import { AttributeConfigurationType } from '@/types/iot-portal';

export { AttributeConfigurationType } from '@/types/iot-portal';

export interface AttributeConfigurationTypeMeta {
  value: AttributeConfigurationType;
  label: string;
  description?: string;
}

export const ATTRIBUTE_CONFIGURATION_TYPE_META: {
  readonly [k in AttributeConfigurationType]: Readonly<AttributeConfigurationTypeMeta>;
} = Object.freeze({
  [AttributeConfigurationType.CheckboxAttributeConfiguration]: {
    value: AttributeConfigurationType.CheckboxAttributeConfiguration,
    label: 'Ja/Nein (Checkbox)',
  },
  [AttributeConfigurationType.SelectAttributeConfiguration]: {
    value: AttributeConfigurationType.SelectAttributeConfiguration,
    label: 'Auswahl',
  },
  [AttributeConfigurationType.SingleLineTextAttributeConfiguration]: {
    value: AttributeConfigurationType.SingleLineTextAttributeConfiguration,
    label: 'Einzeiliger Text',
  },
  [AttributeConfigurationType.CalculationAttributeConfiguration]: {
    value: AttributeConfigurationType.CalculationAttributeConfiguration,
    label: 'Kalkulation',
  },
});
