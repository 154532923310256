































































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Column } from '@/features/ui/table/model';
import { ArrayProp, BooleanProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { DomainUiAlertTableAlertFragment } from './__generated__/DomainUiAlertTableAlertFragment';
import { BASE_COLUMNS } from './model';

@Component
export default class AlertTable extends Mixins(DeviceRoleMapMixin) {
  @ArrayProp(() => [])
  private readonly alerts!: DomainUiAlertTableAlertFragment[];

  @ArrayProp()
  private readonly enabledColumns?: string[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @ArrayProp(() => [])
  private hiddenColumns!: string[];

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  @BooleanProp()
  private readonly headless!: boolean;

  @BooleanProp()
  private readonly resetLink!: boolean;

  @ArrayProp(() => [])
  private readonly additionalColumns!: Column[];

  private get columns(): Column[] {
    return BASE_COLUMNS.concat(this.additionalColumns).concat(
      this.resetLink ? [{ name: 'reset', align: 'right' }] : [],
    );
  }

  private isSameDay(startDate: Date, endDate: Date | null): boolean {
    if (endDate === null) {
      return false;
    }

    return (
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate()
    );
  }
}
