import { isEmpty } from 'lodash';
import { ConsumptionSpotsItems, ContinuousConsumptionMetric, PropertyTreeNodeAttribute } from '../../model';
import { calculatePercentageDiff, spotContainsMetrics } from '../../util';
import { CONSUMPTION_MIXIN_META } from '../constants';

export function calculateConsumption(
  spotItems: ConsumptionSpotsItems,
  propertyAttributes: PropertyTreeNodeAttribute[],
): number {
  return spotItems.reduce((acc, currentItem) => {
    const metrics = currentItem.metrics as ContinuousConsumptionMetric[];
    const beforeMetrics = currentItem.beforeMetrics as ContinuousConsumptionMetric[];

    if (isEmpty(metrics)) {
      return acc + 0.0;
    }

    return (
      acc +
      CONSUMPTION_MIXIN_META[currentItem.role].consumptionCalculationMethod(
        metrics[0]?.latest.continuous ?? 0,
        beforeMetrics[0]?.latest.continuous ?? 0,
        propertyAttributes,
        currentItem.attributes,
      )
    );
  }, 0.0);
}

export function calculateConsumptionDiff(
  previousSpotItems: ConsumptionSpotsItems,
  currentConsumption: number,
  attributes: PropertyTreeNodeAttribute[],
): number {
  if (previousSpotItems.length === 0 || !spotContainsMetrics(previousSpotItems)) {
    return NaN;
  }
  const previousConsumption = calculateConsumption(previousSpotItems, attributes);
  return calculatePercentageDiff(previousConsumption, currentConsumption);
}
