






import { Component, Vue } from 'vue-property-decorator';
import HeatingSystemSummaryContent from './HeatingSystemSummaryContent.vue';

@Component({
  components: { HeatingSystemSummaryContent },
  inheritAttrs: false,
})
export default class HeatingSystemSummaryPanel extends Vue {}
