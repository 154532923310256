















import { Input } from '@/features/ui/inputs/model';
import { ObjectProp } from '@/util/prop-decorators';
import { isEqual, isNumber, isString } from 'lodash';
import { Component, Model, Vue } from 'vue-property-decorator';
import { DomainUiInputAlertRuleDeploymentVariableDefinitionFragment } from './__generated__/DomainUiInputAlertRuleDeploymentVariableDefinitionFragment';

type InputOption = NonNullable<DomainUiInputAlertRuleDeploymentVariableDefinitionFragment['inputOptions']>[number];

@Component({
  data() {
    return { inputRef: undefined };
  },
})
export default class InputAlertRuleDeploymentVariable extends Vue implements Input {
  @Model('update')
  private readonly value!: unknown;

  @ObjectProp(true)
  private readonly variableDefinition!: DomainUiInputAlertRuleDeploymentVariableDefinitionFragment;

  public readonly $refs!: { input: Vue & Input };

  private inputRef?: Vue & Input;

  private get model(): unknown {
    return this.denormalizeValue(this.value);
  }

  private set model(value: unknown) {
    this.$emit('update', this.normalizeValue(value));
  }

  public get normalizedValue(): unknown {
    return this.normalizeValue(this.model);
  }

  public get empty(): boolean {
    return this.normalizedValue === null;
  }

  public get pristine(): boolean {
    return this.inputRef?.pristine ?? true;
  }

  private get type(): string | null {
    return this.variableDefinition.inputType;
  }

  private get selectOptions(): InputOption[] {
    return this.variableDefinition.inputOptions ?? [];
  }

  private get placeholder(): unknown {
    return this.variableDefinition.defaultValue === null
      ? undefined
      : this.denormalizeValue(this.variableDefinition.defaultValue);
  }

  private mounted(): void {
    this.inputRef = this.$refs.input;
  }

  private updated(): void {
    this.inputRef = this.$refs.input;
  }

  private denormalizeValue(value: unknown): unknown {
    switch (this.type) {
      case 'string':
        return isString(value) ? value : '';

      case 'number':
        return isNumber(value) ? String(value) : '';

      case 'select':
        return this.selectOptions.find((option) => isEqual(option.value, value)) ?? null;

      default:
        return JSON.stringify(value, undefined, 2);
    }
  }

  private normalizeValue(value: unknown): unknown {
    switch (this.type) {
      case 'string':
        return isString(value) && value !== '' ? value : null;

      case 'number': {
        const num = value === '' ? Number.NaN : Number(value);

        return Number.isNaN(num) ? null : num;
      }

      case 'select':
        return value === null ? null : (value as InputOption).value;

      default:
        return null;
    }
  }
}
