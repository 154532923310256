var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.activityTreeNodeBookmarks},scopedSlots:_vm._u([{key:"node",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.treeNode.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"activities",fn:function(ref){
var row = ref.row;
return [_c('ui-inline-list',{attrs:{"items":row.activities,"comparator":_vm.activityNameComparator},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,true)})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('copy-url', row)}}},[_vm._v("Eintragungslink kopieren")]),_vm._v(_vm._s('\u00a0')),_c('ui-link',{on:{"click":function($event){return _vm.$emit('remove', row)}}},[_vm._v("Deaktivieren")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }