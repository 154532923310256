























































import { DayOfWeek, DAY_OF_WEEK_META, INTERVAL_UNIT_META } from '@/features/domain-ui/interval/constants';
import { Option } from '@/features/ui/inputs/model';
import { IntervalUnit } from '@/types/iot-portal';
import { BooleanProp } from '@/util/prop-decorators';
import moment from 'moment';
import { Component, Model, Vue } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';
import { de } from 'vuejs-datepicker/dist/locale';
import { Input } from '@/features/ui/inputs/model';
import { RecurringInterval } from './model';

type IntervalUnitOption = Option<IntervalUnit>;
type DayOfWeekOption = Option<DayOfWeek>;

const createRecurringInterval = (
  amount: number,
  unit: IntervalUnit | null,
  daysOfWeek: number[],
  startDate: Date,
  endDate: Date | null,
): RecurringInterval | undefined => {
  if (unit === null) {
    return undefined;
  }
  return {
    unit,
    amount,
    daysOfWeek: unit === IntervalUnit.Day ? daysOfWeek : null,
    startDate,
    endDate: endDate,
  };
};

@Component({
  inheritAttrs: false,
  data() {
    return { de };
  },
  components: { Datepicker },
})
export default class InputRecurringInterval extends Vue implements Input {
  @Model('update')
  private readonly value!: RecurringInterval | undefined | null;

  @BooleanProp()
  private nonClearable!: boolean;

  private get startDate(): Date {
    return this.value?.startDate ?? moment(this.value?.startDate).startOf('day').toDate();
  }

  private set startDate(value: Date) {
    this.$emit(
      'update',
      createRecurringInterval(
        Number(this.amount),
        this.intervalUnit,
        this.daysOfWeek,
        value,
        this.endDate !== null && value > this.endDate ? value : this.endDate,
      ),
    );
  }

  private get endDate(): Date | null {
    return this.value?.endDate ?? null;
  }

  private set endDate(value: Date | null) {
    this.$emit(
      'update',
      createRecurringInterval(Number(this.amount), this.intervalUnit, this.daysOfWeek, this.startDate, value),
    );
  }

  private onEndDateSwitchUpdate(value: boolean): void {
    if (value === false) {
      this.endDate = null;
    } else {
      const currentDate = new Date();
      this.endDate = currentDate > this.startDate ? currentDate : this.startDate;
    }
  }

  private get showDaysOfWeek(): boolean {
    return this.intervalUnit === IntervalUnit.Day && Number(this.amount) === 1;
  }

  private get startDateDisabledDates(): { to: Date } {
    return {
      to: new Date(),
    };
  }

  private get endDateDisabledDates(): { to: Date } {
    const currentDate = new Date();
    return {
      to: currentDate > this.startDate ? currentDate : this.startDate,
    };
  }

  private get amount(): string {
    return String(this.value?.amount ?? 1);
  }

  private set amount(value: string) {
    this.$emit(
      'update',
      createRecurringInterval(Number(value), this.intervalUnit, this.daysOfWeek, this.startDate, this.endDate),
    );
  }

  private get intervalUnit(): IntervalUnit | null {
    return this.value?.unit ?? null;
  }

  private set intervalUnit(value: IntervalUnit | null) {
    this.$emit(
      'update',
      createRecurringInterval(Number(this.amount), value, this.daysOfWeek, this.startDate, this.endDate),
    );
  }

  private get daysOfWeek(): number[] {
    return this.value?.daysOfWeek ?? [];
  }

  private set daysOfWeek(value: number[]) {
    this.$emit(
      'update',
      createRecurringInterval(Number(this.amount), this.intervalUnit, value, this.startDate, this.endDate),
    );
  }

  public get normalizedValue(): RecurringInterval | undefined {
    return this.value
      ? createRecurringInterval(Number(this.amount), this.intervalUnit, this.daysOfWeek, this.startDate, this.endDate)
      : undefined;
  }
  public get empty(): boolean {
    return this.value === null || this.value === undefined;
  }
  public get pristine(): boolean {
    return false;
  }

  private get intervalUnitOptions(): IntervalUnitOption[] {
    return [
      {
        value: IntervalUnit.Day,
        label: INTERVAL_UNIT_META[IntervalUnit.Day].labelAdverb,
      },
      {
        value: IntervalUnit.Week,
        label: INTERVAL_UNIT_META[IntervalUnit.Week].labelAdverb,
      },
      {
        value: IntervalUnit.Month,
        label: INTERVAL_UNIT_META[IntervalUnit.Month].labelAdverb,
      },
      {
        value: IntervalUnit.Year,
        label: INTERVAL_UNIT_META[IntervalUnit.Year].labelAdverb,
      },
      {
        value: IntervalUnit.QUARTER_HOURLY,
        label: INTERVAL_UNIT_META[IntervalUnit.QUARTER_HOURLY].labelAdverb,
      },
    ];
  }

  private get daysOfWeekOptions(): DayOfWeekOption[] {
    return [
      {
        value: DayOfWeek.Monday,
        label: DAY_OF_WEEK_META[DayOfWeek.Monday].label,
      },
      {
        value: DayOfWeek.Tuesday,
        label: DAY_OF_WEEK_META[DayOfWeek.Tuesday].label,
      },
      {
        value: DayOfWeek.Wednesday,
        label: DAY_OF_WEEK_META[DayOfWeek.Wednesday].label,
      },
      {
        value: DayOfWeek.Thursday,
        label: DAY_OF_WEEK_META[DayOfWeek.Thursday].label,
      },
      {
        value: DayOfWeek.Friday,
        label: DAY_OF_WEEK_META[DayOfWeek.Friday].label,
      },
      {
        value: DayOfWeek.Saturday,
        label: DAY_OF_WEEK_META[DayOfWeek.Saturday].label,
      },
      {
        value: DayOfWeek.Sunday,
        label: DAY_OF_WEEK_META[DayOfWeek.Sunday].label,
      },
    ];
  }
}
