var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.heatingSystems,"disabled-columns":_vm.disabledColumns,"is-row-expanded":_vm.isRowExpanded},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_c('ui-icon-state-indicator',{staticClass:"side",attrs:{"state":_vm.HeatingSystemHealthStateIndicatorMap[_vm.getHeatingSystemHealth(row)]}})]}},{key:"statusText",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getHeatingSystemStatustext(row)))]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('ui-link',{staticClass:"space",on:{"click":function($event){return _vm.$emit('heating-system-click', row)}}},[_c('ui-icon',{attrs:{"icon":"edit"}})],1),_c('ui-link',{staticClass:"space",on:{"click":function($event){return _vm.$emit('show-measurements', row)}}},[_c('ui-icon',{attrs:{"icon":"cog"}})],1),_c('ui-link',{staticClass:"space",on:{"click":function($event){return _vm.$emit('toggle-row-expansion', index)}}},[_c('ui-icon',{attrs:{"icon":"graph"}})],1)]}},{key:"row.expanded",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-heating-system-graph',{staticClass:"container",attrs:{"heating-system":row}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }