






















import { formatConsumption } from '@/features/domain-ui/eed-consumption/util';
import numeral from '@/util/numeral-locale';
import { Component, Vue } from 'vue-property-decorator';
import { BooleanProp, DateProp, NumberProp, StringProp } from '@/util/prop-decorators';
import moment from 'moment';

@Component
export default class ConsumptionComparisonPanel extends Vue {
  @StringProp()
  private readonly label!: string;

  @DateProp()
  private readonly period!: Date;

  @NumberProp()
  private readonly previousConsumption!: number;

  @NumberProp()
  private readonly percentageDiff!: number;

  @BooleanProp()
  private readonly shouldBeShown!: boolean;

  @BooleanProp()
  private readonly userHasAccess!: boolean;

  @StringProp()
  private readonly unit!: string;

  private get year(): string {
    return moment(this.period).format('YYYY');
  }

  private get month(): string {
    return moment(this.period).format('MMMM');
  }

  private get hasData(): boolean {
    return !isNaN(this.percentageDiff);
  }

  private get formattedPercentageDiff(): string {
    if (this.percentageDiff < 0) {
      if (Number.isInteger(this.percentageDiff)) {
        return numeral(this.percentageDiff).format('-0%');
      } else {
        return numeral(this.percentageDiff).format('-0.0%');
      }
    } else {
      if (Number.isInteger(this.percentageDiff)) {
        return numeral(this.percentageDiff).format('+0%');
      }
      return numeral(this.percentageDiff).format('+0.0%');
    }
  }

  private get formattedConsumption(): string {
    return formatConsumption(this.previousConsumption);
  }
}
