




import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiGatewayIconGatewayFragment } from './__generated__/DomainUiGatewayIconGatewayFragment';
import GatewayImage from './gateway.svg?vue';

@Component({ components: { GatewayImage } })
export default class GatewayIcon extends Vue {
  @ObjectProp()
  private readonly gateway!: DomainUiGatewayIconGatewayFragment;
}
