






import { Component, Vue } from 'vue-property-decorator';
import SpotSummaryContent from './SpotSummaryContent.vue';

@Component({
  components: { SpotSummaryContent },
  inheritAttrs: false,
})
export default class SpotSummaryPanel extends Vue {}
