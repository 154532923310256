var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.heatingSystems,"disabled-columns":_vm.disabledColumns,"is-row-expanded":_vm.isRowExpanded},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"alarm-status"},[_c('ui-icon-state-indicator',{staticClass:"side",attrs:{"state":_vm.HeatingSystemHealthStateIndicatorMap[row.health]}}),(row.statuses && row.statuses.length === 0)?_c('p',[_vm._v("Aktuell liegen keine Störungsmeldungen vor.")]):_c('p',[_vm._v("Es liegen aktuell Störungsmeldungen vor:")])],1)]}},{key:"statuses",fn:function(ref){
var row = ref.row;
return [_c('ul',{staticStyle:{"list-style-type":"none","padding":"0"}},_vm._l((row.statuses),function(status,idx){return _c('li',[_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(((idx + 1) + ") " + (_vm.formattedMessage(status))))}})])}),0)]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('ui-link',{staticClass:"space",on:{"click":function($event){return _vm.$emit('toggle-row-expansion', index)}}},[_c('ui-icon',{attrs:{"icon":"graph"}})],1)]}},{key:"row.expanded",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-heating-system-metrics-graph',{staticClass:"container",attrs:{"heating-system":row.components}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }