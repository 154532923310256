


























import { BooleanProp, ObjectProp } from '@/util/prop-decorators';
import { HeatingSystemHealth } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiHeatingSystemSummaryPanelCollectionFragment } from './__generated__/DomainUiHeatingSystemSummaryPanelCollectionFragment';
import { HEATING_SYSTEM_HEALTH_META } from '@/features/domain-ui/heating-system-health/constants';

type HealthState = { health: HeatingSystemHealth; count: number };

type Point = { value: number; color: string };

@Component
export default class HeatingSystemSummaryContent extends Vue {
  @ObjectProp(true)
  private readonly collection!: DomainUiHeatingSystemSummaryPanelCollectionFragment;

  @BooleanProp()
  private readonly detailsLink!: boolean;

  private get healthStates(): HealthState[] {
    const stateMap = new Map(this.collection.healthAggregations.map(({ health, count }) => [health, count]));

    return Object.values(HEATING_SYSTEM_HEALTH_META)
      .sort((a, b) => a.order - b.order)
      .map((healthMeta) => ({
        health: healthMeta.value,
        count: stateMap.get(healthMeta.value) ?? 0,
      }));
  }

  private get points(): Point[] {
    return this.healthStates.map(({ count, health }) => ({
      value: count,
      color: HEATING_SYSTEM_HEALTH_META[health].color,
    }));
  }
}
