



























import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class ThermometerDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private readonly ranges = [
    { from: 0, to: 0.2, color: '#52a300' },
    { from: 0.2, to: 0.4, color: '#a8b400' },
    { from: 0.4, to: 0.6, color: '#fecb00' },
    { from: 0.6, to: 0.8, color: '#f5a623' },
    { from: 0.8, to: 1, color: '#e60000' },
  ];
}
