






























































import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Vue, Component } from 'vue-property-decorator';
import { DomainUiContractTableContractFragment } from './__generated__/DomainUiContractTableContractFragment';

const TRIM = /^[\s,]+|[\s,]+$/g;

@Component
export default class ContractTable extends Vue {
  @ArrayProp(() => [])
  private readonly contracts?: DomainUiContractTableContractFragment[];

  @ArrayProp(() => [])
  private readonly excludeColumns!: string[];

  private readonly columns: Column[] = [
    { name: 'contract' },
    { name: 'sales' },
    { name: 'company' },
    { name: 'dates' },
    { name: 'numbers', align: 'right' },
    { name: 'action', label: '', align: 'right' },
  ];

  private formatAddress(address: DomainUiContractTableContractFragment['companyAddress']): string | undefined {
    return `${address?.street ?? ''}, ${address?.postal ?? ''} ${address?.locality ?? ''}`.replace(TRIM, '');
  }
}
