












































import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiPropertyInfoPanelContactFragment } from './__generated__/DomainUiPropertyInfoPanelContactFragment';
import { DomainUiPropertyInfoPanelInfoFragment } from './__generated__/DomainUiPropertyInfoPanelInfoFragment';

@Component
export default class PropertyInfoPanel extends Vue {
  @ObjectProp()
  private readonly info?: DomainUiPropertyInfoPanelInfoFragment;

  @ObjectProp()
  private readonly contact?: DomainUiPropertyInfoPanelContactFragment;
}
