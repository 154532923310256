












import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import { ConsumptionMixin } from '../mixins/consumption-mixin';
import { PeriodType } from '@/features/domain-ui/eed-consumption/model';
import { NumberProp } from '@/util/prop-decorators';
import ConsumptionComparisonPanel from './ConsumptionComparisonPanel.vue';

@Component({
  components: {
    ConsumptionComparisonPanel,
  },
})
export default class CurrentMonthPreviousYearConsumptionComparison extends Mixins(ConsumptionMixin) {
  @NumberProp()
  private readonly currentConsumption!: number;

  protected readonly periodType = PeriodType.CURRENT_MONTH_PREVIOUS_YEAR;

  protected readonly checkableMetric: boolean = true;

  private get percentageDiff(): number {
    return this.computePercentageDiff(this.currentConsumption);
  }

  private get previousYearConsumption(): number {
    if (this.percentageDiff === 100) {
      return 0;
    }

    return (100 * this.currentConsumption) / (this.percentageDiff + 100);
  }

  private get yearBefore(): Date {
    return moment(this.period).subtract(1, 'year').toDate();
  }

  private get userHasAccess(): boolean {
    return this.userAccessDate < this.yearBefore;
  }
}
