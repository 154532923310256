import { AlertNotificationBehavior } from '@/types/iot-portal';

export { AlertNotificationBehavior } from '@/types/iot-portal';

export interface AlertNotificationBehaviorMeta<T extends AlertNotificationBehavior = AlertNotificationBehavior> {
  value: T;
  label: string;
  optionLabel?: string;
  description?: string;
}

export const ALERT_NOTIFICATION_BEHAVIOR_META: {
  readonly [K in AlertNotificationBehavior]: Readonly<AlertNotificationBehaviorMeta<K>>;
} = Object.freeze({
  [AlertNotificationBehavior.NONE]: {
    value: AlertNotificationBehavior.NONE,
    label: 'Keine Benachrichtigung',
  },
  [AlertNotificationBehavior.START]: {
    value: AlertNotificationBehavior.START,
    label: 'Zu Beginn einer Regelverletzung',
  },
});
