






























































import { ArrayProp, RequiredProp } from '@/util/prop-decorators';
import moment from 'moment';
import { NIL } from 'uuid';
import { Component, Mixins } from 'vue-property-decorator';
import { Option } from '@/features/ui/inputs/model';
import { HeatingSystemCollectionWithPaginationQuery } from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import { removeLabelFromName } from '@/util/heating-systems';

import {
  DomainUiAppHeatingSystemHeatingSystemGraphSpotQuery,
  DomainUiAppHeatingSystemHeatingSystemGraphSpotQueryVariables,
} from '@/features/domain-ui/heating-system-graph/__generated__/DomainUiAppHeatingSystemHeatingSystemGraphSpotQuery';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { PropertySpotQuery, PropertySpotQueryVariables } from './__generated__/PropertySpotQuery';
import query from '@/features/domain-ui/heating-system-graph/query.gql';
import propertySpotQuery from './property-spot.gql';
import { DeviceRoleAggregationMetric } from '@/features/core/model';
import { MetricDescriptorInput, SpotRole, MetricResolutionInput } from '@/types/iot-portal';
import { RangeInterval } from '@/types/hsc-types';

type HeatingSystem = HeatingSystemCollectionWithPaginationQuery['heatingSystemsList']['items'][number];

type Spot = DomainUiAppHeatingSystemHeatingSystemGraphSpotQuery['spot'];

type PropertySpot = PropertySpotQuery['propertySpot'];

@Component({
  apollo: {
    spot: {
      query,
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables(this: HeatingSystemMetricsGraph): DomainUiAppHeatingSystemHeatingSystemGraphSpotQueryVariables {
        return {
          spotId: this.selectedSpot.value as string,
        };
      },
      skip(this: HeatingSystemMetricsGraph) {
        // Skip the query if selectedSpot.value is undefined or not valid
        return !this.selectedSpot.value;
      },
    },
    propertySpot: {
      query: propertySpotQuery,
      fetchPolicy: 'no-cache',
      variables(this: HeatingSystemMetricsGraph): PropertySpotQueryVariables {
        return {
          id: this.heatingSystem.siteId as string,
        };
      },
    },
  },
  data() {
    return {
      spots: undefined,
      propertySpotResult: undefined,
      selectedMetrics: [],
      availableOnGraphMetrics: [],
      startDate: moment().subtract(30, 'days').toDate(),
      stopDate: moment().toDate(),
      aggregationInterval: RangeInterval.HOUR,
      aggregator: undefined,
      aggregation: undefined,
    };
  },
})
export default class HeatingSystemMetricsGraph extends Mixins(DeviceRoleMapMixin) {
  @RequiredProp()
  private readonly heatingSystem!: HeatingSystem;

  private spot?: Spot;
  private propertySpot?: PropertySpot;
  private selectedMetrics!: string[];
  private availableOnGraphMetrics!: string[];
  private startDate!: Date;
  private stopDate?: Date;
  private aggregationInterval?: Duration;
  private aggregator?: undefined;

  @ArrayProp(() => [])
  private readonly metricNames!: string[];

  private selectedSpot: Option = {
    value: NIL,
    label: 'Gerätename',
  };

  private get descriptorDates(): Pick<MetricDescriptorInput, 'start' | 'stop'> {
    const timezoneOffset = moment(this.startDate).utcOffset();

    const start =
      this.startDate === undefined
        ? undefined
        : moment(this.startDate).utc().add(timezoneOffset, 'minutes').startOf('day').toDate();
    const stop = this.stopDate === undefined ? undefined : moment(this.stopDate).add(1, 'day').startOf('day').toDate();

    return { start, stop };
  }

  private get aggregationMetricSet(): Set<string> {
    return new Set(
      this.deviceRoleMap[SpotRole.OUTSIDE_TEMPERATURE]?.aggregationMetricNames?.map(({ name }) => name) ?? [],
    );
  }

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[SpotRole.OUTSIDE_TEMPERATURE]?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get aggregationResolutionMap(): Map<string, MetricResolutionInput | undefined> {
    const resolutionOutput: Map<string, MetricResolutionInput | undefined> = new Map(
      Array.from(this.aggregationMetricMap.entries())
        .filter((entry) => (this.aggregator ? entry[1].aggregators.includes(this.aggregator) : true))
        .map(([aggregationMetricName, metric]: [string, DeviceRoleAggregationMetric]) => {
          if (metric === undefined) {
            return [aggregationMetricName, undefined];
          }

          return [
            aggregationMetricName,
            {
              intervalLength: this.aggregationInterval ?? 'PT1H',
              timeSource: metric.timeSource,
              aggregator: this.aggregator,
            },
          ];
        }),
    );
    return resolutionOutput;
  }

  private get selectableMetrics(): string[] {
    return this.heatingSystem.heatingSystemMeasurementGroups.flatMap(({ heatingSystemMeasurements }) => {
      return heatingSystemMeasurements.map(({ metricName }) => metricName);
    });
  }

  private get spotIds(): string[] {
    return [
      ...new Set(
        this.heatingSystem.heatingSystemMeasurementGroups.flatMap(({ heatingSystemMeasurements }) => {
          return heatingSystemMeasurements.map(({ spotId }) => spotId);
        }),
      ),
    ];
  }

  private get heatingSystemComponents(): Map<string, string> {
    const heatingSystemComponents = new Map<string, string>(
      this.heatingSystem.heatingSystemMeasurementGroups.flatMap(({ heatingSystemMeasurements }) => {
        return heatingSystemMeasurements.map((measurement) => {
          return [measurement.spotId, removeLabelFromName(measurement.measurementName, measurement.metricLabel)];
        });
      }),
    );

    return heatingSystemComponents;
  }

  private get selectSpotOptions(): Option[] {
    const options: Option[] = [];

    for (const heatingSystemComponent of this.heatingSystemComponents.entries()) {
      options.push({ label: heatingSystemComponent[1], value: heatingSystemComponent[0] });
    }

    return options;
  }

  private resetDefaultValues(): void {
    this.selectedMetrics = [];
    this.startDate = moment().subtract(30, 'days').toDate();
    this.stopDate = undefined;
    this.aggregationInterval = RangeInterval.HOUR;
    this.aggregator = undefined;
  }

  private updateSelectedSpot(option: Option): void {
    this.resetDefaultValues();
    this.selectedSpot = option;
    this.$apollo.queries.spot.refetch();
  }

  private mounted(): void {
    const [firstComponentName] = this.heatingSystemComponents.values();
    const [firstCompoentSpotId] = this.heatingSystemComponents.keys();

    this.selectedSpot = {
      value: firstCompoentSpotId,
      label: firstComponentName,
    };
  }
}
