var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.properties},scopedSlots:_vm._u([{key:"path",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name)),_c('br'),_vm._v(_vm._s([row.bgswid, row.externalId].filter(Boolean).join(' • ') || '–'))]}},{key:"address",fn:function(ref){
var value = ref.value;
return [(value === null)?[_vm._v("–")]:[_vm._v(_vm._s(value.street || '–')),_c('br'),_vm._v(_vm._s(value.postal || '–')+" "+_vm._s(value.locality || '–'))]]}},{key:"technicalContact",fn:function(ref){
var row = ref.row;
return [(row.technicalContact === null)?[_vm._v("–")]:[_c('ui-link',{attrs:{"href":("mailto:" + (row.technicalContact.email))}},[_vm._v(_vm._s(row.technicalContact.name)),_c('br'),_vm._v(_vm._s(row.technicalContact.email))])]]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('property-click', row)}}},[_vm._v("Objekt anzeigen")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }