



















import { DeviceRole } from '@/features/core/model';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiSpotRoleAggregationWidgetFragment } from './__generated__/DomainUiSpotRoleAggregationWidgetFragment';

@Component
export default class SpotRoleAggregationWidget extends Vue {
  @ObjectProp(true)
  private readonly aggregation!: DomainUiSpotRoleAggregationWidgetFragment;

  @ObjectProp(true)
  private readonly role!: DeviceRole;
}
