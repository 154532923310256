






import { BooleanProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { TreeNodeTypeMeta, TREE_NODE_TYPE_META } from './constants';

@Component
export default class TreeNodeType extends Vue {
  @EnumProp(...Object.keys(TREE_NODE_TYPE_META))
  private readonly type!: import('./constants').TreeNodeType;

  @BooleanProp()
  private readonly inline!: boolean;

  @BooleanProp()
  private readonly plural!: boolean;

  private get meta(): TreeNodeTypeMeta {
    return TREE_NODE_TYPE_META[this.type];
  }

  private get field(): keyof TreeNodeTypeMeta {
    return this.plural ? 'labelPlural' : 'label';
  }
}
