import { getConverter } from '@/features/core/util/metric-formatters';
import { AttributeMissingError } from '@/features/domain-ui/eed-consumption/error/AttributeMissingError';
import {
  ConsumptionAttribute,
  HeatConsumptionAttributesType,
  PropertyTreeNodeAttribute,
} from '@/features/domain-ui/eed-consumption/model';
import { roundDecimalPlaces } from '@/features/domain-ui/eed-consumption/util';

export function getAttributeValue(attributes: ConsumptionAttribute[], attributeName: string): number | null {
  const matchedAttribute = attributes.find(({ configuration }) => configuration.name === attributeName);
  return matchedAttribute ? parseFloat(matchedAttribute.value) : null;
}

export function calculateHeatConsumption(
  lastValue: number,
  previousValue: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _propertyAttributes: PropertyTreeNodeAttribute[],
  spotAttributes: ConsumptionAttribute[],
): number {
  const conversionFactor = getAttributeValue(spotAttributes, HeatConsumptionAttributesType.CONVERSION_FACTOR);
  const baseSensitivity = getAttributeValue(spotAttributes, HeatConsumptionAttributesType.BASE_SENSITIVITY);

  if (!conversionFactor) {
    throw new AttributeMissingError(HeatConsumptionAttributesType.CONVERSION_FACTOR);
  }

  if (!baseSensitivity) {
    throw new AttributeMissingError(HeatConsumptionAttributesType.BASE_SENSITIVITY);
  }

  return roundDecimalPlaces(((lastValue - previousValue) * conversionFactor) / baseSensitivity);
}

export function calculateHeatConsumptionWithoutConversion(
  lastValue: number,
  previousValue: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _propertyAttributes: PropertyTreeNodeAttribute[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _spotAttributes: ConsumptionAttribute[],
): number {
  return roundDecimalPlaces((getConverter('currentEnergy') as (value: number) => number)(lastValue - previousValue));
}
