












import { formatMetricName } from '@/features/core/util/metric-formatters';
import { Metrics } from '@/features/core/util/metrics';
import { ExportColumn } from '@/features/ui/data-export/model';
import { TreeNodeType } from '@/types/iot-portal';
import { hashCode } from '@/util/hash-code';
import { ArrayProp, BooleanProp } from '@/util/prop-decorators';
import { groupBy, keyBy } from 'lodash';
import moment, { Moment } from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { TREE_NODE_TYPE_META } from '../tree-node-type/constants';
import { formatTextAttribute, formatTextMetricTime, SpotAttribute, SpotsExportRow, SpotsExportSpot } from './model';

@Component
export default class SpotsExport extends Vue {
  @ArrayProp(() => [])
  private readonly spots!: SpotsExportSpot[];

  @BooleanProp()
  private readonly disabled!: boolean;

  private readonly baseColumns: ExportColumn<SpotsExportRow>[] = [
    { name: 'id', selectable: false, accessor: 'spot.id', label: 'ID' },
    { name: 'name', selectable: false, accessor: 'spot.name', label: 'Name', descriptions: ['', 'importierbar'] },
    { name: 'role', accessor: 'spot.role', label: 'Rolle' },
    { name: 'roomName', accessor: 'spot.roomName', label: 'Raum', descriptions: ['', 'importierbar'] },
    { name: 'notes', accessor: 'spot.notes', label: 'Notizen', descriptions: ['', 'importierbar'] },
    { name: 'serial', accessor: 'spot.activeDeviceMount.serial', label: 'Gerätenummer' },
    { name: 'manufacturer', accessor: 'spot.activeDeviceMount.manufacturer', label: 'Hersteller' },
    { name: 'health', accessor: 'spot.health', label: 'Zustand' },
    { name: 'healthChangeDate', accessor: 'spot.healthChangeDate', label: 'Letzte Zustandsänderung' },
    { name: 'latestTime', accessor: 'metrics.latestTime', label: 'Letzte Daten' },
  ];

  private computeAttributeNameHashCode({ configuration }: SpotAttribute): number {
    return hashCode(configuration.name);
  }

  private get rows(): SpotsExportRow[] {
    return this.spots.map((spot) => ({
      spot,
      metrics: Metrics.create(spot.metrics.map(({ latest }) => latest)),
      pathTypes: Object.fromEntries(
        Object.entries(groupBy(spot.path.items, '__typename')).map(([type, nodes]) => [
          type,
          nodes.map(({ name }) => name),
        ]),
      ) as Record<TreeNodeType, string[]>,
      attributeMap: keyBy(spot.attributes, this.computeAttributeNameHashCode),
    }));
  }

  private get pathColumns(): ExportColumn<SpotsExportRow>[] {
    const counts: Partial<Record<TreeNodeType, number>> = {};

    for (const { pathTypes } of this.rows) {
      for (const [type, { length }] of Object.entries(pathTypes) as [TreeNodeType, string[]][]) {
        counts[type] = Math.max(counts[type] ?? 0, length);
      }
    }

    function* generateColumns(type: TreeNodeType, count: number): Generator<ExportColumn<SpotsExportRow>> {
      for (let i = 0; i < count; i++) {
        yield {
          name: type + (count > 1 ? i + 1 : ''),
          label: TREE_NODE_TYPE_META[type].label + (count > 1 ? ` ${i + 1}` : ''),
          accessor: `pathTypes.${type}[${i}]`,
        };
      }
    }

    return Object.entries(counts).flatMap(([type, count]) => [...generateColumns(type as TreeNodeType, count ?? 0)]);
  }

  private get metricNames(): string[] {
    return [...new Set(this.spots.flatMap(({ metrics }) => metrics.map(({ latest }) => latest.name)))];
  }

  public get metricColumns(): ExportColumn<SpotsExportRow>[] {
    return this.metricNames
      .flatMap((name) => [
        {
          name: `metric_${name}_time`,
          accessor: `metrics.all.${name}.time`,
          label: `${formatMetricName(name)} Zeitpunkt`,
          descriptions: ['Metrik'],
          group: 'Metriken',
          formatText: formatTextMetricTime,
          align: 'right' as const,
        },
        {
          name: `metric_${name}_value`,
          accessor: `metrics.all.${name}.value`,
          label: `${formatMetricName(name)} Wert`,
          descriptions: ['Metrik'],
          group: 'Metriken',
          align: 'right' as const,
        },
      ])
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public get attributeColumns(): ExportColumn<SpotsExportRow>[] {
    const attributes = [
      ...new Map(
        this.spots
          .flatMap(({ attributes }) => attributes)
          .map(({ configuration }) => [configuration.name, configuration]),
      ).values(),
    ].filter(({ treeNodeTypes }) => treeNodeTypes.includes(TreeNodeType.Spot));

    return attributes
      .map(({ name, __typename }) => ({
        name: `attribute_${btoa(name)}`,
        accessor: `attributeMap.${hashCode(name)}`,
        label: name,
        descriptions: ['Attribut', 'importierbar'],
        group: 'Attribute',
        formatText: formatTextAttribute,
        align: __typename === 'CheckboxAttributeConfiguration' ? ('right' as const) : ('left' as const),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  private get exportColumns(): ExportColumn<SpotsExportRow>[] {
    return [...this.baseColumns, ...this.pathColumns, ...this.attributeColumns, ...this.metricColumns];
  }

  private now(): Moment {
    return moment();
  }
}
