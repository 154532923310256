








import { formatMetricName, getMetricUnit } from '@/features/core/util/metric-formatters';
import { BooleanProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiMetricSpotFragment } from './__generated__/DomainUiMetricSpotFragment';
import { SpotRole } from '@/types/iot-portal';

@Component
export default class Metric extends Vue {
  @StringProp()
  private readonly name?: string;

  @ObjectProp()
  private readonly spot?: DomainUiMetricSpotFragment;

  @BooleanProp()
  private readonly inline!: boolean;

  @StringProp('–')
  private readonly emptyMessage!: string;

  private get configuration(): DomainUiMetricSpotFragment['metricConfigurations'][number] | undefined {
    return this.spot?.metricConfigurations.find(({ name }) => name === this.name);
  }

  private get label(): string {
    if (this.spot?.role === SpotRole.OUTSIDE_TEMPERATURE) {
      return 'Außentemperatur';
    }

    const originalLabel = this.configuration?.label || formatMetricName(this.name ?? '');

    return this.updateMetricLabel(originalLabel);
  }

  private updateMetricLabel(label: string): string {
    if (label === 'Trinkwarmwasser Warmwasser') {
      return 'Warmwasseraufbereitung Warmwasser';
    } else if (label === 'Trinkwarmwasser Zirkulation') {
      return 'Warmwasseraufbereitung Zirkulation';
    } else {
      return label;
    }
  }

  private get shortLabel(): string | undefined {
    return this.configuration?.shortLabel ?? undefined;
  }

  private get unit(): string | undefined {
    const unit = getMetricUnit(this.name ?? '');

    return unit === '' ? undefined : unit;
  }
}
