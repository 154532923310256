






import { BooleanProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import type { AlertRuleStrategyType as Strategy, AlertRuleStrategyTypeMeta } from './constants';
import { ALERT_RULE_STRATEGY_TYPE_META, UNKNOWN_ALERT_RULE_STRATEGY_TYPE_META } from './constants';

@Component
export default class AlertRuleStrategyType extends Vue {
  @EnumProp(...Object.keys(ALERT_RULE_STRATEGY_TYPE_META))
  private readonly type!: Strategy;

  @BooleanProp()
  private readonly inline!: boolean;

  private get meta(): AlertRuleStrategyTypeMeta {
    return ALERT_RULE_STRATEGY_TYPE_META[this.type] ?? UNKNOWN_ALERT_RULE_STRATEGY_TYPE_META;
  }
}
