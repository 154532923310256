









import { BooleanProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiAlertCategoryFragment } from './__generated__/DomainUiAlertCategoryFragment';

@Component
export default class AlertCategory extends Vue {
  @ObjectProp()
  private readonly category?: DomainUiAlertCategoryFragment;

  @BooleanProp()
  private readonly inline!: boolean;

  @StringProp('–')
  private readonly emptyMessage!: string;
}
