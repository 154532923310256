import { HeatingSystemHealth } from '@/types/iot-portal';

export interface HeatingSystemHealthMeta {
  value: HeatingSystemHealth;
  color: string;
  label: string;
  order: number;
}

export const HEATING_SYSTEM_HEALTH_META: {
  readonly [k in HeatingSystemHealth]: Readonly<HeatingSystemHealthMeta>;
} = Object.freeze({
  [HeatingSystemHealth.INACTIVE]: {
    value: HeatingSystemHealth.INACTIVE,
    color: '#000000',
    label: 'unbenutzt',
    order: 0,
  },
  [HeatingSystemHealth.UNKNOWN]: {
    value: HeatingSystemHealth.UNKNOWN,
    color: '#4a4d4e',
    label: 'unbekannt',
    order: 1,
  },
  [HeatingSystemHealth.CRITICAL]: {
    value: HeatingSystemHealth.CRITICAL,
    color: '#fe004f',
    label: 'kritisch',
    order: 2,
  },
  [HeatingSystemHealth.DEGRADED]: {
    value: HeatingSystemHealth.DEGRADED,
    color: '#f5a623',
    label: 'eingeschränkt',
    order: 3,
  },
  [HeatingSystemHealth.OK]: { value: HeatingSystemHealth.OK, color: '#66cd05', label: 'störungsfrei', order: 4 },
});
