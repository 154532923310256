

































import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp } from '@/util/prop-decorators';
import { ApartmentRow } from './model';

@Component
export default class ApartmentTable extends Vue {
  @ArrayProp(() => [])
  private readonly apartments!: ApartmentRow[];

  private readonly columns = [
    { name: 'name', label: 'Wohnung/Raum' },
    // { name: 'directoryExternalId', field: 'floor.property.directory.meta.externalId', label: 'Objekt ID Kunde' },
    // { name: 'propertyExternalId', field: 'floor.property.meta.externalId', label: 'SubOIKd' },
    { name: 'address', label: 'Adresse' },
    { name: 'bgswid', label: 'Objekt-ID Vodafone' },
    { name: 'externalId', label: 'ID Kunde' },
    { name: 'externalType', label: 'Nutzungsart' },
    { name: 'floorName', label: 'Etagenname' },
    { name: 'floorLevel', label: 'Etagenebene' },
    { name: 'position', label: 'Lage' },
    { name: 'size', label: 'Größe' },
    { name: 'technicalContact', label: 'Technischer Kontakt' },
    { name: 'visualizationHint', label: 'Typ' },
    { name: 'actions', label: '' },
  ];

  private formatterVisualizationHint(name: string): string {
    switch (name) {
      case 'APARTMENT':
        return 'Wohnung';
      case 'STAIRWELL':
        return 'Treppenhaus';
      case 'ROOM':
        return 'Raum';
      default:
        return '\u2013';
    }
  }
}
