

















import { StateColors } from '@/features/ui/icon-state-indicator/state-property-mixin';
import { IsRowExpanded } from '@/features/ui/table/model';
import { HeatingSystemHealth } from '@/types/iot-portal';
import { ArrayProp, FunctionProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { BASE_COLUMNS } from './model';
import { DomainUiHeatingSystemTableHeatingSystemFragment } from './__generated__/DomainUiHeatingSystemTableHeatingSystemFragment';

@Component
export default class HeatingSystemTable extends Vue {
  @ArrayProp(() => [])
  private readonly heatingSystems!: DomainUiHeatingSystemTableHeatingSystemFragment[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @FunctionProp(() => false)
  private readonly isRowExpanded!: IsRowExpanded;

  private readonly columns = BASE_COLUMNS;

  private readonly HeatingSystemHealthStateIndicatorMap = Object.freeze({
    [HeatingSystemHealth.OK]: StateColors.GREEN,
    [HeatingSystemHealth.DEGRADED]: StateColors.YELLOW,
    [HeatingSystemHealth.CRITICAL]: StateColors.RED,
    [HeatingSystemHealth.INACTIVE]: StateColors.GRAY,
    [HeatingSystemHealth.UNKNOWN]: StateColors.LIGHT_GRAY,
  });

  private getHeatingSystemStatustext(row: DomainUiHeatingSystemTableHeatingSystemFragment): string | undefined {
    if (this.isHeatingSystemEnerIq(row)) {
      return this.heatingSystemEnerIqStatusText(row);
    }

    switch (this.getHeatingSystemHealth(row)) {
      case HeatingSystemHealth.CRITICAL: {
        if (row.alerts.more) {
          return row.alerts.items.map(({ ruleDeployment }) => ruleDeployment).join(', ');
        }
        return 'Geräteereichbarkeit kritisch. Das Gerät wird aktuell überprüft.';
      }
      case HeatingSystemHealth.DEGRADED: {
        if (row.alerts.more) {
          return row.alerts.items.map(({ ruleDeployment }) => ruleDeployment).join(', ');
        }
        return 'Geräteereichbarkeit eingeschränkt. Geräteprüfungen können erforderlich werden.';
      }
      case HeatingSystemHealth.INACTIVE:
        return 'Heizungsanlage inaktiv';
      case HeatingSystemHealth.OK:
        return 'Keine Meldungen Vorhanden';
      case HeatingSystemHealth.UNKNOWN:
        return 'Unbekannter Status';
    }
  }

  private isHeatingSystemEnerIq(row: DomainUiHeatingSystemTableHeatingSystemFragment): boolean {
    return row.enerIqConnectedEconomicUnit?.id ? true : false;
  }

  private heatingSystemEnerIqStatusText(row: DomainUiHeatingSystemTableHeatingSystemFragment): string | undefined {
    switch (this.getHeatingSystemHealth(row)) {
      case HeatingSystemHealth.CRITICAL: {
        return 'Der Betrieb der Heizungseinlage ist kritisch und ein Handwerkereinsatz ist zwingend notwendig.';
      }
      case HeatingSystemHealth.DEGRADED: {
        return 'Die Heizungsanlage ist in ihrem Betrieb beeinträchtigt. Ein Handwerkereinsatz wird empfohlen.';
      }
      case HeatingSystemHealth.INACTIVE:
        return 'Heizungsanlage inaktiv.';
      case HeatingSystemHealth.OK:
        return 'Keine Meldungen Vorhanden.';
      case HeatingSystemHealth.UNKNOWN:
        return 'Unbekannter Status.';
    }
  }

  private getHeatingSystemHealth(row: DomainUiHeatingSystemTableHeatingSystemFragment): HeatingSystemHealth {
    if (!this.isHeatingSystemEnerIq(row)) {
      return row.health;
    }

    switch (row.enerIqConnectedEconomicUnit?.state?.indicator) {
      case 'success': {
        return HeatingSystemHealth.OK;
      }
      case 'warning': {
        return HeatingSystemHealth.DEGRADED;
      }
      case 'error': {
        return HeatingSystemHealth.CRITICAL;
      }
      default:
        return HeatingSystemHealth.UNKNOWN;
    }
  }
}
