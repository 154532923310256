






















































































































import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Column, IsRowExpanded } from '@/features/ui/table/model';
import { ArrayProp, FunctionProp, ObjectProp } from '@/util/prop-decorators';
import { Component, ModelSync, Vue } from 'vue-property-decorator';
import { ActivityLogEntryTableRow } from './model';

@Component
export default class ActivityLogEntryTable extends Vue {
  @ArrayProp(() => [])
  private readonly activityLogEntries!: ActivityLogEntryTableRow[];

  @ArrayProp()
  private readonly enabledColumns?: string[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  @ModelSync('selectedActivityLogEntries', 'update:selectedActivityLogEntries', { type: Array, default: [] })
  private modelSelectedActivityLogEntries!: ActivityLogEntryTableRow[];

  @FunctionProp(() => false)
  private readonly isRowExpanded!: IsRowExpanded;

  private readonly columns: Column[] = [
    { name: 'checkInDate', label: 'Beginn' },
    { name: 'checkOutDate', label: 'Ende' },
    { name: 'node', label: 'Ort' },
    { name: 'activity', label: 'Aktivität' },
    { name: 'rating', label: 'Bewertung' },
    { name: 'notes', label: 'Notizen' },
    { name: 'action', label: '', align: 'right' },
  ];
}
