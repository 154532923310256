













import { BooleanProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { GatewayHealthMeta, GATEWAY_HEALTH_META } from './constants';

@Component
export default class GatewayHealth extends Vue {
  @EnumProp(...Object.keys(GATEWAY_HEALTH_META))
  private readonly health!: import('@/types/iot-portal').GatewayHealth;

  @BooleanProp()
  private readonly capitalize!: boolean;

  @BooleanProp()
  private readonly obsolete!: boolean;

  @BooleanProp()
  private readonly inline!: boolean;

  @BooleanProp(false)
  private readonly noTooltip!: boolean;

  private get meta(): GatewayHealthMeta {
    return GATEWAY_HEALTH_META[this.health];
  }
}
