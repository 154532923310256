
















import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { createTreeNodeTypeComparator } from '../tree-node-type/constants';
import { AttributeDefinitionRow } from './model';

@Component
export default class AttributeDefinitionTable extends Vue {
  @ArrayProp(() => [])
  private readonly attributeDefinitions!: AttributeDefinitionRow[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  private readonly comparator = createTreeNodeTypeComparator('labelPlural');

  private readonly columns: Column[] = [
    { name: 'name', field: 'configuration.name', label: 'Name' },
    { name: 'type', field: 'configuration.__typename', label: 'Typ' },
    { name: 'description', field: 'configuration.description', label: 'Beschreibung' },
    { name: 'treeNodeTypes', field: 'configuration.treeNodeTypes', label: 'Anwendbar auf' },
    { name: 'actions', label: '' },
  ];
}
