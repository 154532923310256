import { SpotRole } from '@/types/iot-portal';
import { calculateHeatConsumption, calculateHeatConsumptionWithoutConversion } from '../heat-consumption/util';
import { HeatConsumptionAttributesType, Unit } from '../model';
import { calculateWaterConsumption } from '../water-consumption/util';
import { ConsumptionMixinMeta } from './model';

export const CONSUMPTION_MIXIN_META: { [key: string]: ConsumptionMixinMeta } = Object.freeze({
  [SpotRole.HEAT_COST_ALLOCATOR]: {
    consumptionCalculationMethod: calculateHeatConsumption,
    attributesNames: [HeatConsumptionAttributesType.BASE_SENSITIVITY, HeatConsumptionAttributesType.CONVERSION_FACTOR],
    unit: Unit.KWH,
  },
  [SpotRole.HEAT_METER_COUNTER]: {
    consumptionCalculationMethod: calculateHeatConsumptionWithoutConversion,
    attributesNames: [],
    unit: Unit.KWH,
  },
  [SpotRole.WATER_METER_HOT]: {
    consumptionCalculationMethod: calculateWaterConsumption,
    attributesNames: [],
    unit: Unit.KWH,
  },
});
