import { Metrics } from '@/features/core/util/metrics';
import { TextFormatter } from '@/features/ui/data-export/model';
import { TreeNodeType } from '@/types/iot-portal';
import {
  DomainUiSpotsExportSpotFragment,
  DomainUiSpotsExportSpotFragment_attributes,
} from './__generated__/DomainUiSpotsExportSpotFragment';

export type SpotsExportSpot = DomainUiSpotsExportSpotFragment;

export type SpotAttribute = DomainUiSpotsExportSpotFragment_attributes;

export interface SpotsExportRow {
  spot: SpotsExportSpot;
  pathTypes: Record<TreeNodeType, string[]>;
  metrics: Metrics;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- can not use unknown because of generic bivariance
export const formatTextMetricTime: TextFormatter<any, Date> = (row, column, value) =>
  value === undefined ? '' : new Date(value).toISOString();

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- can not use unknown because of generic bivariance
export const formatTextAttribute: TextFormatter<any, SpotsExportSpot['attributes'][number]> = (
  _row,
  _column,
  { configuration, value },
) => {
  switch (configuration.__typename) {
    case 'CheckboxAttributeConfiguration':
      return value === '' ? 'Nein' : 'Ja';

    case 'SingleLineTextAttributeConfiguration':
    case 'SelectAttributeConfiguration':
    case 'CalculationAttributeConfiguration':
      return value;
  }
};
