import { DomainUiAlertRuleStrategyFragment } from './__generated__/DomainUiAlertRuleStrategyFragment';

export type AlertRuleStrategyType = DomainUiAlertRuleStrategyFragment['__typename'];

export interface AlertRuleStrategyTypeMeta<T extends AlertRuleStrategyType = AlertRuleStrategyType> {
  value: T;
  label: string;
  optionLabel?: string;
  description?: string;
}

export const UNKNOWN_ALERT_RULE_STRATEGY_TYPE_META: Readonly<AlertRuleStrategyTypeMeta<'UnknownAlertRuleStrategy'>> =
  Object.freeze({
    value: 'UnknownAlertRuleStrategy',
    label: 'Nicht konfiguriert',
    optionLabel: '–',
  });

export const ALERT_RULE_STRATEGY_TYPE_META: {
  readonly [K in AlertRuleStrategyType]?: Readonly<AlertRuleStrategyTypeMeta<K>>;
} = Object.freeze({
  UnknownAlertRuleStrategy: UNKNOWN_ALERT_RULE_STRATEGY_TYPE_META,
  LatestMetricsAlertRuleStrategy: {
    value: 'LatestMetricsAlertRuleStrategy',
    label: 'Metriken',
    optionLabel: 'Metriken',
  },
});

export function createAlertRuleStrategyTypeComparator(
  field: keyof AlertRuleStrategyTypeMeta = 'label',
): (a: AlertRuleStrategyType, b: AlertRuleStrategyType) => number {
  return (a, b) =>
    (ALERT_RULE_STRATEGY_TYPE_META[a]?.[field] ?? '').localeCompare(ALERT_RULE_STRATEGY_TYPE_META[b]?.[field] ?? '');
}
