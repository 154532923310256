




import { EnumProp, BooleanProp } from '@/util/prop-decorators';
import { GatewayHealth } from '@/types/iot-portal';
import { Component as ComponentInterface } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import GatewayIcon from './gateway.svg?vue';
import MasterGatewayIcon from './master-gateway.svg?vue';

@Component
export default class IconGateway extends Vue {
  @EnumProp('UNKNOWN', 'CRITICAL', 'DEGRADED', 'OK')
  private readonly health!: GatewayHealth;

  @BooleanProp()
  private readonly master!: boolean;

  private get icon(): ComponentInterface {
    return this.master ? MasterGatewayIcon : GatewayIcon;
  }

  private readonly gatewayIcon = GatewayIcon;
  private readonly masterGatewayIcon = MasterGatewayIcon;
}
