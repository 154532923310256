






import { ArrayProp, BooleanProp } from '@/util/prop-decorators';
import { chunk } from 'lodash';
import moment, { Moment } from 'moment';
import QRCode from 'qrcode';
import { Component, Vue } from 'vue-property-decorator';
import { generateBookmarkTokenUrl } from '../activity-tree-node-bookmark/util';
import { ActivityTreeNodeBookmarksQrCodeExportBookmark } from './model';

@Component
export default class ActivityTreeNodeBookmarksQrCodeExport extends Vue {
  @ArrayProp(() => [])
  private readonly bookmarks!: ActivityTreeNodeBookmarksQrCodeExportBookmark[];

  @BooleanProp()
  private readonly disabled!: boolean;

  private get now(): Moment {
    return moment();
  }

  private static async html(bookmarks: ActivityTreeNodeBookmarksQrCodeExportBookmark[]): Promise<HTMLElement> {
    const root = document.createElement('div');
    /**
     * we want a layout of 2X3
     * we split into chunks of 6 and page break after each chunk
     * */
    const chunks = chunk(bookmarks, 6);
    for (const [index, chunk] of chunks.entries()) {
      const grid = document.createElement('span');
      grid.setAttribute(
        'style',
        `display: grid; grid-template-columns: repeat(2, 1fr); ${index !== 0 ? 'page-break-before: always' : ''}`,
      );
      for (const [bookmarkIndex, bookmark] of chunk.entries()) {
        const cell = document.createElement('div');
        /**
         * the height of a row is 1/3 of an A4
         * we calculate it based on the height of the A4 format in pt, converted to px and rounded down
         * https://github.com/parallax/jsPDF/blob/v2.4.0/src/jspdf.js#L276
         * https://github.com/parallax/jsPDF/blob/v2.4.0/src/jspdf.js#L3214
         * */
        cell.setAttribute(
          'style',
          `
            display: flex;
            flex-direction: column;
            height: ${374}px;
            box-sizing: border-box;
            padding: 30px;
            ${bookmarkIndex < 4 ? 'border-bottom: 1px solid rgb(74, 77, 78);' : ''}
            ${bookmarkIndex % 2 === 0 ? 'border-right: 1px solid rgb(74, 77, 78);' : ''}
          `,
        );
        const qrCodeContainer = document.createElement('div');
        qrCodeContainer.setAttribute(
          'style',
          'flex-grow: 1; display: flex; align-items: center; justify-content: center;',
        );
        const qrCode = document.createElement('img');
        qrCode.setAttribute('src', await QRCode.toDataURL(generateBookmarkTokenUrl(bookmark.token), { margin: 0 }));
        qrCode.setAttribute('style', 'width: 200px; height: 200px;');
        qrCodeContainer.appendChild(qrCode);
        cell.appendChild(qrCodeContainer);
        const addressContainer = document.createElement('div');
        addressContainer.setAttribute(
          'style',
          'display: flex; align-items: center; height: 140x; color: rgb(74, 77, 78);',
        );
        const address = document.createElement('div');
        address.innerHTML = bookmark.treeNode.path.items.map(({ name }) => name).join('/');
        addressContainer.appendChild(address);
        cell.appendChild(addressContainer);
        grid.appendChild(cell);
      }
      root.appendChild(grid);
    }
    return root;
  }

  private async generate(): Promise<void> {
    const bookmarks = [...this.bookmarks];
    const html2pdf = await import('html2pdf.js');
    html2pdf
      .default()
      .set({
        margin: 0,
        filename: `aktivitaeten-qr-codes-${this.now.format('YYYY-MM-DD-HHmm')}.pdf`,
        jsPDF: { format: 'a4' },
      })
      .from(await ActivityTreeNodeBookmarksQrCodeExport.html(bookmarks))
      .save();
  }
}
