import { VendorApiType } from '@/types/iot-portal';
import { stripIndent } from 'common-tags';

export { VendorApiType } from '@/types/iot-portal';

export interface VendorApiTypeMeta {
  value: VendorApiType;
  label: string;
  description?: string;
}

export const VENDOR_API_TYPE_META: { readonly [k in VendorApiType]: Readonly<VendorApiTypeMeta> } = Object.freeze({
  [VendorApiType.BOSCH]: {
    value: VendorApiType.BOSCH,
    label: 'Bosch',
  },
  [VendorApiType.DORMAKABA]: {
    value: VendorApiType.DORMAKABA,
    label: 'Dormakaba',
  },
  [VendorApiType.KONE]: {
    value: VendorApiType.KONE,
    label: 'Kone',
  },
  [VendorApiType.OIL_FOX]: {
    value: VendorApiType.OIL_FOX,
    label: 'Oil Fox',
  },
  [VendorApiType.AufzugheldenApi]: {
    value: VendorApiType.AufzugheldenApi,
    label: 'Aufzughelden',
    description: stripIndent`
      Aufzughelden bietet Nutzungs- und Wartungsdaten über Aufzüge an.
    `,
  },
  [VendorApiType.ComgyApi]: {
    value: VendorApiType.ComgyApi,
    label: 'Comgy',
    description: stripIndent`
      Comgy liefert Nutzungsdaten verschiedener Sensoren.
    `,
  },
  [VendorApiType.ViessmannApi]: {
    value: VendorApiType.ViessmannApi,
    label: 'Viessmann',
    description: stripIndent`
      Viessmann bietet Nutzungs- und Wartungsdaten über Heizungssysteme an.
    `,
  },
  [VendorApiType.NukiApi]: {
    value: VendorApiType.NukiApi,
    label: 'Nuki',
    description: stripIndent`
      Nuki bietet digitalisierte Schließanlagen an.
    `,
  },
  [VendorApiType.EnerIqApi]: {
    value: VendorApiType.EnerIqApi,
    label: 'EnerIQ',
    description: stripIndent`
      EnerIQ bietet Auswertungen und Überwachung von Heizungsanlagen an.
    `,
  },
  [VendorApiType.IvmApi]: {
    value: VendorApiType.IvmApi,
    label: 'IVM Professional®',
    description: stripIndent`
      Vodafone GK 4.0 Benachrichtigungen an die IVM Professional® Software für Wohnungswirtschaft zur weiterführenden Bearbeitung senden.
    `,
  },
  [VendorApiType.IvmNotificationApi]: {
    value: VendorApiType.IvmNotificationApi,
    label: 'IVM Professional® Notification Api',
    description: stripIndent`
      Vodafone GK 4.0 Benachrichtigungen an die IVM Professional® Software für Wohnungswirtschaft zur weiterführenden Bearbeitung senden.
    `,
  },
  [VendorApiType.IvmConsumptionApi]: {
    value: VendorApiType.IvmConsumptionApi,
    label: 'IVM Professional® Consumption API',
    description: stripIndent`
    Vodafone GK 4.0 Ermöglicht den Zugriff auf Verbrauchsdaten für IVM Professional®.
    `,
  },
  [VendorApiType.AareonApi]: {
    value: VendorApiType.AareonApi,
    label: 'Aareon',
    description: stripIndent`
      Aareon bietet Auswertungen und Überwachung von Heizungsanlagen an.
    `,
  },
  [VendorApiType.AlertMailConfiguration]: {
    value: VendorApiType.AlertMailConfiguration,
    label: 'Alarmierungsmail',
    description: stripIndent`
      Vodafone GK 4.0 Benachrichtigungen an eine E-Mail-Adresse zur weiterführenden Bearbeitung senden.
    `,
  },
  [VendorApiType.ConsumptionDataExchange]: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch',
    description: stripIndent`
      Verbrauchsdaten an Dritte senden.
    `,
  },
  ['ConsumptionDataExchangeVarysSW']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch Stadtwerke Eisleben',
    description: stripIndent`
      Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt. 
    `,
  },
  ['ConsumptionDataExchangeVarysMDL']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch VarysMDL',
    description: stripIndent`
      Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt. 
    `,
  },
  ['ConsumptionDataExchangeAllpas']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch Allpas',
    description: stripIndent`
    Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt.
    `,
  },
  ['ConsumptionDataExchangeAsko']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch Asko',
    description: stripIndent`
    Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt.
    `,
  },
  ['ConsumptionDataExchangeEADBuchholz']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch EAD Buchholz',
    description: stripIndent`
    Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt.
    `,
  },
  ['ConsumptionDataExchangeEADZeulenroda']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch EAD Zeulenroda',
    description: stripIndent`
    Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt.
    `,
  },
  ['ConsumptionDataExchangeIbaenergie']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch Ibaenergie',
    description: stripIndent`
    Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt.
    `,
  },
  ['ConsumptionDataExchangeMevivoEco']: {
    value: VendorApiType.ConsumptionDataExchange,
    label: 'Verbrauchsdatenaustausch MevivoEco',
    description: stripIndent`
    Mit der Aktivierung dieses Datenanbieters werden alle relevanten Daten ausgetauscht und die Verbrauchsdaten bereitgestellt.
    `,
  },
  [VendorApiType.ExternalSftpConfiguration]: {
    value: VendorApiType.ExternalSftpConfiguration,
    label: 'Externer SFTP Server',
    description: stripIndent`
      Externen SFTP-Server für Exporte mit dem Vodafone GK 4.0 Portal verknüpfen.
    `,
  },
});
