












import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import { ConsumptionMixin } from '../mixins/consumption-mixin';
import { PeriodType } from '@/features/domain-ui/eed-consumption/model';
import { NumberProp } from '@/util/prop-decorators';
import ConsumptionComparisonPanel from './ConsumptionComparisonPanel.vue';

@Component({
  components: {
    ConsumptionComparisonPanel,
  },
})
export default class MonthlyConsumptionComparison extends Mixins(ConsumptionMixin) {
  @NumberProp()
  private readonly currentConsumption!: number;

  protected readonly periodType = PeriodType.PREVIOUS_MONTH;

  protected readonly checkableMetric: boolean = true;

  private get percentageDiff(): number {
    return this.computePercentageDiff(this.currentConsumption);
  }

  private get monthBefore(): Date {
    return moment(this.period).subtract(1, 'month').toDate();
  }

  private get userHasAccess(): boolean {
    return this.userAccessDate < this.monthBefore;
  }
}
