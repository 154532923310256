






import { BooleanProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import type { AlertNotificationBehavior as Behavior, AlertNotificationBehaviorMeta } from './constants';
import { ALERT_NOTIFICATION_BEHAVIOR_META } from './constants';

@Component
export default class AlertNotificationBehavior extends Vue {
  @EnumProp(...Object.keys(ALERT_NOTIFICATION_BEHAVIOR_META))
  private readonly behavior!: Behavior;

  @BooleanProp()
  private readonly inline!: boolean;

  private get meta(): AlertNotificationBehaviorMeta {
    return ALERT_NOTIFICATION_BEHAVIOR_META[this.behavior];
  }
}
