var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{ref:"wizard",attrs:{"title":"Geräte importieren"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("default",null,null,props)]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-flex',{staticStyle:{"max-width":"480px"}},[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-input-field',{attrs:{"label":"Bitte wählen Sie die zu importierende Datei im CSV-Format aus"}},[_c('ui-input-file',{attrs:{"disabled":_vm.analyzing || _vm.importing,"accept":".csv"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),(_vm.analyzing)?_c('ui-cell',{attrs:{"basis":1}},[_c('spinner',{attrs:{"dark":""}}),_vm._v(" Analysiere zu importierende Datei…")],1):[_vm._l((_vm.analyzeErrors),function(ref,index){
var line = ref.line;
var message = ref.message;
return _c('ui-cell',{key:("analyzeError" + index),attrs:{"basis":1}},[_c('ui-form-message',{attrs:{"headline":line === undefined ? undefined : ("Zeile " + line),"error":""}},[_vm._v(_vm._s(message))])],1)}),_vm._l((_vm.analyzeWarnings),function(ref,index){
var line = ref.line;
var message = ref.message;
return _c('ui-cell',{key:("analyzeWarning" + index),attrs:{"basis":1}},[_c('ui-form-message',{attrs:{"headline":line === undefined ? undefined : ("Überspringe Zeile " + line),"warning":""}},[_vm._v(_vm._s(message))])],1)}),(_vm.importFieldOptions.length > 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-input-field',{attrs:{"label":"Zu importierende Spalten"}},[_c('ui-input-checkbox-list',{attrs:{"options":_vm.importFieldOptions},model:{value:(_vm.importFields),callback:function ($$v) {_vm.importFields=$$v},expression:"importFields"}})],1)],1):_vm._e(),(_vm.importRows.length > 0)?[_c('ui-cell',{attrs:{"basis":1}},[_vm._v(_vm._s(_vm.importedSpotCount)+" Geräte importiert / "+_vm._s(_vm.importRows.length)+" Geräte gefunden")]),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!_vm.importReady,"busy":_vm.importing},on:{"click":function($event){return _vm.importSpots(hide)}}},[_vm._v("Importieren")])],1),_vm._l((_vm.importErrors),function(ref,index){
var line = ref.line;
var message = ref.message;
return _c('ui-cell',{key:("importError" + index),attrs:{"basis":1}},[_c('ui-form-message',{attrs:{"headline":line === undefined ? undefined : ("Zeile " + line + " konnte nicht importiert werden"),"error":""}},[_vm._v(_vm._s(message))])],1)})]:_vm._e()]],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }