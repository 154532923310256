





















import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Column } from '@/features/ui/table/model';
import { ArrayProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { ActivityTreeNodeBookmarkTableRow } from './model';

type Activity = ActivityTreeNodeBookmarkTableRow['activities'][number];

function activityNameComparator(a: Activity, b: Activity): number {
  return a.name.localeCompare(b.name);
}

@Component({
  data() {
    return {
      activityNameComparator,
    };
  },
})
export default class ActivityTreeNodeBookmarkTable extends Vue {
  @ArrayProp(() => [])
  private readonly activityTreeNodeBookmarks!: ActivityTreeNodeBookmarkTableRow[];

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  private readonly columns: Column[] = [
    { name: 'node', label: 'Ort' },
    { name: 'activities', label: 'Aktivitäten' },
    { name: 'action', label: '', align: 'right' },
  ];
}
