























import { Input } from '@/features/ui/inputs/model';
import { BooleanProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';
import { DomainUiInputAttributeAttributeConfigurationFragment } from './__generated__/DomainUiInputAttributeAttributeConfigurationFragment';

@Component({
  data() {
    return { inputRef: undefined };
  },
})
export default class InputAttribute extends Vue implements Input {
  @Model('update')
  private readonly value!: unknown;

  @ObjectProp()
  private readonly attributeConfiguration?: DomainUiInputAttributeAttributeConfigurationFragment;

  @BooleanProp()
  private readonly noLabel!: boolean;

  public readonly $refs!: { input: Vue & Input };

  private inputRef?: Vue & Input;

  private get model(): string {
    return String(this.value);
  }

  private set model(value: string) {
    this.$emit('update', value);
  }

  public get normalizedValue(): string {
    return this.model;
  }

  public get empty(): boolean {
    return this.normalizedValue === '';
  }

  public get pristine(): boolean {
    return this.inputRef?.pristine ?? true;
  }

  private get type(): string {
    return this.attributeConfiguration?.__typename ?? '';
  }

  private mounted(): void {
    this.inputRef = this.$refs.input;
  }

  private updated(): void {
    this.inputRef = this.$refs.input;
  }
}
