




















import { StateColors } from '@/features/ui/icon-state-indicator/state-property-mixin';
import { IsRowExpanded } from '@/features/ui/table/model';
import { HeatingSystemHealth } from '@/types/iot-portal';
import { ArrayProp, FunctionProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { BASE_COLUMNS, HeatingSystemSummary } from './model';

@Component
export default class HeatingSystemListTable extends Vue {
  @ArrayProp(() => [])
  private readonly heatingSystems!: HeatingSystemSummary[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @FunctionProp(() => false)
  private readonly isRowExpanded!: IsRowExpanded;

  private readonly columns = BASE_COLUMNS;

  private readonly HeatingSystemHealthStateIndicatorMap = Object.freeze({
    [HeatingSystemHealth.OK]: StateColors.GREEN,
    [HeatingSystemHealth.DEGRADED]: StateColors.YELLOW,
    [HeatingSystemHealth.CRITICAL]: StateColors.RED,
    [HeatingSystemHealth.INACTIVE]: StateColors.GRAY,
    [HeatingSystemHealth.UNKNOWN]: StateColors.LIGHT_GRAY,
  });

  private formattedMessage(message: string): string {
    // replace any "\n\n" with "br".
    // replace tripple asterisks with empty space.
    return message.replace(/\n+/g, '<br>').replace(/\*+/g, '');
  }
}
