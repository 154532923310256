













import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp } from '@/util/prop-decorators';
import { TreeNodeFileRow } from './model';

@Component
export default class TreeNodeFileTable extends Vue {
  @ArrayProp(() => [])
  private readonly files!: TreeNodeFileRow[];

  private readonly columns = [
    { name: 'label', label: 'Name' },
    { name: 'mimeType', label: 'Typ' },
    { name: 'date', label: 'Geändert' },
    { name: 'actions', label: '' },
  ];
}
