import { render, staticRenderFns } from "./PrimaryMetric.vue?vue&type=template&id=36538036&scoped=true&lang=pug&"
import script from "./PrimaryMetric.vue?vue&type=script&lang=ts&"
export * from "./PrimaryMetric.vue?vue&type=script&lang=ts&"
import style0 from "./PrimaryMetric.vue?vue&type=style&index=0&id=36538036&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36538036",
  null
  
)

export default component.exports