var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"enabled-columns":_vm.enabledColumns,"disabled-columns":_vm.disabledColumns,"rows":_vm.activityLogEntries,"is-row-expanded":_vm.isRowExpanded,"selected-rows":_vm.modelSelectedActivityLogEntries,"selectable":""},on:{"update:selectedRows":function($event){_vm.modelSelectedActivityLogEntries=$event},"update:selected-rows":function($event){_vm.modelSelectedActivityLogEntries=$event}},scopedSlots:_vm._u([{key:"checkInDate",fn:function(ref){
var row = ref.row;
return [(row.checkInDate === null)?[_vm._v("–")]:_c('div',{staticClass:"no-wrap"},[_c('ui-relative-time',{attrs:{"date":row.checkInDate,"format":"L – LT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
var formattedDate = ref.formattedDate;
return [_vm._v(_vm._s(formattedDate)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(displayDate))])]}}],null,true)})],1)]}},{key:"checkOutDate",fn:function(ref){
var row = ref.row;
return [(row.latestCheckOutDate === null)?[_vm._v("–")]:(row.checkOutDate !== null)?_c('div',{staticClass:"no-wrap"},[_c('ui-relative-time',{attrs:{"date":row.checkOutDate,"reference":row.checkInDate,"suffix":false,"to":true,"format":"L – LT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
var formattedDate = ref.formattedDate;
return [_vm._v(_vm._s(formattedDate)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v("dauerte "+_vm._s(displayDate))])]}}],null,true)})],1):_c('div',{staticClass:"no-wrap"},[_c('ui-relative-time',{attrs:{"date":row.latestCheckOutDate,"min":"P0D","format":"L – LT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
var formattedDate = ref.formattedDate;
var inRange = ref.inRange;
return [(inRange)?[_vm._v("Zur Zeit aktiv"),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v("Läuft ab "+_vm._s(displayDate))])]:[_vm._v("Abgelaufen"),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(formattedDate))])]]}}],null,true)})],1)]}},{key:"node",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.treeNode.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"rating",fn:function(ref){
var row = ref.row;
var expanded = ref.expanded;
var index = ref.index;
return [(row.ratings.averageScore === null)?[_vm._v("–")]:_c('ui-tooltip',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('toggle-row-expansion', index)}}},[_c('ui-flex',{attrs:{"center":"","nowrap":""}},[_c('ui-cell',{attrs:{"grow":0}},[_c('ui-rating-smiley',{attrs:{"score":row.ratings.averageScore,"size":30,"stroke-width":2}})],1),_c('ui-cell',{attrs:{"grow":0}},[_c('ui-toggle-expand',{attrs:{"expanded":expanded}})],1)],1)],1)]},proxy:true},{key:"content",fn:function(){return [_vm._v("Durchschnittliche Bewertung: "+_vm._s((row.ratings.averageScore * 100).toFixed(0))),_c('br'),_vm._v("Anzahl der Bewertungen: "+_vm._s(row.ratings.count)),_c('br')]},proxy:true}],null,true)})]}},{key:"activity",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.activityName)),_c('div',{staticClass:"text-light"},[(row.serviceProvider)?[_vm._v(_vm._s(row.serviceProvider.name || '–')),_c('br'),(row.serviceProvider.firstName && row.serviceProvider.lastName)?[_vm._v(_vm._s(row.serviceProvider.firstName)+" "+_vm._s(row.serviceProvider.lastName))]:_vm._e()]:[_vm._v(_vm._s(row.contractor))]],2)]}},{key:"notes",fn:function(ref){
var row = ref.row;
return [(row.notes === null)?[_vm._v("–")]:[_c('ui-tooltip',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-clickable',[_c('img',{attrs:{"src":require("./notes.svg")}})])]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(row.notes))]},proxy:true}],null,true)})]]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('entry-click', row)}}},[_vm._v("Bearbeiten")])]}},{key:"row.expanded",fn:function(ref){
var row = ref.row;
return [_vm._t("expanded",null,{"row":row})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }