import { render, staticRenderFns } from "./MetricPoint.global.vue?vue&type=template&id=1d8640f7&lang=pug&"
import script from "./MetricPoint.global.vue?vue&type=script&lang=ts&"
export * from "./MetricPoint.global.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports