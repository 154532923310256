













import { BooleanProp, StringProp } from '@/util/prop-decorators';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ConsumptionWidgetInfo extends Vue {
  @BooleanProp()
  private readonly show!: boolean;

  @StringProp()
  private readonly lastReceivedDataTimestamp!: string | null;

  private get formattedLastReceivedDataTimestamp(): string {
    return moment(this.lastReceivedDataTimestamp).format('DD.MM.YYYY, HH:MM');
  }
}
