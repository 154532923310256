

















import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiAlertsSummaryPanelCollectionFragment } from './__generated__/DomainUiAlertsSummaryPanelCollectionFragment';

@Component
export default class AlertsSummaryPanel extends Vue {
  @ArrayProp()
  private readonly collection?: DomainUiAlertsSummaryPanelCollectionFragment;
}
