


























































import { RequiredProp } from '@/util/prop-decorators';
import moment from 'moment';
import { NIL } from 'uuid';
import { Component, Mixins } from 'vue-property-decorator';
import { Option } from '@/features/ui/inputs/model';
import query from './query.gql';
import { DomainUiAppHeatingSystemHeatingSystemGraphHeatingSystemFragment } from './__generated__/DomainUiAppHeatingSystemHeatingSystemGraphHeatingSystemFragment';
import {
  DomainUiAppHeatingSystemHeatingSystemGraphSpotQuery,
  DomainUiAppHeatingSystemHeatingSystemGraphSpotQueryVariables,
} from './__generated__/DomainUiAppHeatingSystemHeatingSystemGraphSpotQuery';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';

type Spot = DomainUiAppHeatingSystemHeatingSystemGraphSpotQuery['spot'];

@Component({
  apollo: {
    spot: {
      query,
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables(this: HeatingSystemGraph): DomainUiAppHeatingSystemHeatingSystemGraphSpotQueryVariables {
        return {
          spotId: this.selectedSpot.value as string,
        };
      },
    },
  },
  data() {
    return {
      spots: undefined,
      selectedMetrics: [],
      availableOnGraphMetrics: [],
      startDate: moment().subtract(30, 'days').toDate(),
      stopDate: moment().toDate(),
      aggregationInterval: undefined,
      aggregator: undefined,
    };
  },
})
export default class HeatingSystemGraph extends Mixins(DeviceRoleMapMixin) {
  @RequiredProp()
  private readonly heatingSystem!: DomainUiAppHeatingSystemHeatingSystemGraphHeatingSystemFragment;

  private spot?: Spot;

  private selectedMetrics!: string[];
  private availableOnGraphMetrics!: string[];
  private startDate!: Date;
  private stopDate?: Date;
  private aggregationInterval?: Duration;
  private aggregator?: undefined;

  private selectedSpot: Option = {
    value: NIL,
    label: 'Gerätename',
  };

  private get selectableMetrics(): string[] {
    return this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [];
  }

  private get spotIds(): string[] {
    return [...new Set(this.heatingSystem.measurements.map((measurement) => measurement?.spot.id ?? NIL))];
  }

  private get selectSpotOptions(): Option[] {
    return this.spotIds.map((spotId) => ({
      label: this.heatingSystem.measurements.find((measurement) => measurement?.spot.id === spotId)?.spot.name ?? '',
      value: spotId,
    }));
  }

  private resetDefaultValues(): void {
    this.selectedMetrics = [];
    this.startDate = moment().subtract(30, 'days').toDate();
    this.stopDate = undefined;
    this.aggregationInterval = undefined;
    this.aggregator = undefined;
  }

  private updateSelectedSpot(option: Option): void {
    this.resetDefaultValues();
    this.selectedSpot = option;
    this.$apollo.queries.spot.refetch();
  }

  private mounted(): void {
    this.selectedSpot = {
      value: this.heatingSystem.measurements[0]?.spot.id ?? NIL,
      label: this.heatingSystem.measurements[0]?.spot.name ?? '',
    };
  }
}
