var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.apartments},scopedSlots:_vm._u([{key:"address",fn:function(ref){
var value = ref.value;
return [(value === null)?[_vm._v(_vm._s('\u2013'))]:[_vm._v(_vm._s(value.street || '\u2013')),_c('br'),_vm._v(_vm._s(value.postal || '\u2013')+" "+_vm._s(value.locality || '\u2013'))]]}},{key:"size",fn:function(ref){
var value = ref.value;
return [(value !== null)?_c('p',[_vm._v(_vm._s(value)+" m²")]):_c('p',[_vm._v(_vm._s('\u2013'))])]}},{key:"technicalContact",fn:function(ref){
var row = ref.row;
return [(row.technicalContact === null)?[_vm._v(_vm._s('\u2013'))]:[_c('ui-link',{attrs:{"href":("mailto:" + (row.technicalContact.email))}},[_vm._v(_vm._s(row.technicalContact.name)),_c('br'),_vm._v(_vm._s(row.technicalContact.email))])]]}},{key:"visualizationHint",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatterVisualizationHint(value)))]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('property-subdivision-click', row)}}},[_vm._v("Wohnung/Raum anzeigen")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }