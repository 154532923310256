var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[(_vm.empty)?_c('ui-form-message',[_vm._v("In diesem Objekt sind keine Geräte installiert")]):_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',{attrs:{"items":"center"}},[(_vm.roleFilter)?_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"value":_vm.role,"options":_vm.roleOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"disabled":_vm.loading,"placeholder":"Gerätetyp"},on:{"update":function($event){return _vm.$emit('update:role', $event || undefined)}}})],1):_vm._e(),(_vm.healthFilter)?_c('ui-cell',{attrs:{"min":150,"max":200}},[_c('ui-input-select',{attrs:{"value":_vm.health,"options":_vm.healthOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Zustand","selectable":function (ref) {
	var count = ref.count;

	return count > 0;
},"disabled":_vm.loading},on:{"update":function($event){return _vm.$emit('update:health', $event || undefined)}}})],1):_vm._e(),(_vm.manufacturerFilter)?_c('ui-cell',{attrs:{"min":150,"max":200}},[_c('ui-input-select',{attrs:{"value":_vm.manufacturer,"options":_vm.manufacturerOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Hersteller","selectable":function (ref) {
	var count = ref.count;

	return count > 0;
},"disabled":_vm.loading},on:{"update":function($event){return _vm.$emit('update:manufacturer', $event || undefined)}}})],1):_vm._e(),(_vm.metricsStopDateFilter)?_c('ui-cell',{attrs:{"min":150,"max":200}},[_c('ui-input-date',{attrs:{"value":_vm.metricsStopDate,"disabled-dates":{ from: _vm.now().add(1, 'day').toDate() },"disabled":_vm.loading,"placeholder":"Aktuell","presets":_vm.metricsStopDatePresets},on:{"update":function($event){return _vm.$emit('update:metricsStopDate', $event || undefined)}}})],1):_vm._e(),(_vm.spotAttributes.length > 0)?_c('ui-cell',{attrs:{"grow":0}},[_c('ui-input-switch',{model:{value:(_vm.showAttributeFilter),callback:function ($$v) {_vm.showAttributeFilter=$$v},expression:"showAttributeFilter"}},[_vm._v("Attributfilter")])],1):_vm._e(),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('domain-ui-spots-import',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{attrs:{"secondary":""},on:{"click":show}},[_vm._v("Importieren")])]}}])})],1),_c('ui-cell',{attrs:{"grow":0}},[(_vm.exportSpots !== undefined && _vm.exportedSpots === undefined)?_c('ui-wizard-button',{attrs:{"busy":_vm.exporting > 0,"secondary":""},on:{"click":_vm.loadExportedSpots}},[_vm._v("Exportieren")]):_c('domain-ui-spots-export',{attrs:{"spots":_vm.exportSpots === undefined ? _vm.spots : _vm.exportedSpots,"initially-visible":_vm.exportSpots !== undefined}})],1),_c('ui-cell',{attrs:{"grow":0}},[_c('ui-table-column-selection',{attrs:{"columns":_vm.tableData.columns},model:{value:(_vm.hiddenColumns),callback:function ($$v) {_vm.hiddenColumns=$$v},expression:"hiddenColumns"}})],1)],1)],1),(_vm.spotAttributes.length > 0 && _vm.showAttributeFilter)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-grid',{attrs:{"min-column-width":200}},_vm._l((_vm.spotAttributes),function(ref){
var attributeDefinitionId = ref.attributeDefinitionId;
var configuration = ref.configuration;
return _c('ui-input-field',{key:attributeDefinitionId,attrs:{"label":configuration.name}},[_c('domain-ui-input-attribute-filter',{attrs:{"value":_vm.attributeFilter[attributeDefinitionId],"attribute-configuration":configuration,"disabled":_vm.loading},on:{"update":function($event){return _vm.updateAttribute(attributeDefinitionId, $event)}}})],1)}),1)],1):_vm._e(),(_vm.loading || _vm.spots.length > 0)?_c('ui-cell',[_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":4,"line-count":2,"header-line-count":2},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('domain-ui-spot-table',{attrs:{"spots":_vm.tableData,"context-tree-node":_vm.contextTreeNode,"hidden-columns":_vm.hiddenColumns},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)},"spot-click":function($event){return _vm.$emit('spot-click', $event)}}})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-pagination',{attrs:{"value":_vm.currentPage,"total-pages":_vm.totalPages},on:{"input":function($event){return _vm.$emit('update:currentPage', $event)}}})],1):_vm._e()],1)]},proxy:true}],null,false,2937879750)})],1):_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Geräte entsprechen dem eingestelltem Filter")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }