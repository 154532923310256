






























































import { GATEWAY_HEALTH_META } from '@/features/domain-ui/gateway-health/constants';
import { DomainUiGatewayTableGatewayFragment } from '@/features/domain-ui/gateway-table/__generated__/DomainUiGatewayTableGatewayFragment';
import { Option } from '@/features/ui/inputs/model';
import { GatewayHealth } from '@/types/iot-portal';
import { ArrayProp, StringProp, NumberProp, BooleanProp } from '@/util/prop-decorators';
import { capitalize, keyBy } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiGatewaysPanelHealthAggregationFragment } from './__generated__/DomainUiGatewaysPanelHealthAggregationFragment';

@Component
export default class GatewaysPanel extends Vue {
  @ArrayProp(() => [])
  private readonly gateways!: DomainUiGatewayTableGatewayFragment[];

  @BooleanProp()
  private readonly loading!: boolean;

  @StringProp()
  private readonly health?: GatewayHealth;

  @StringProp()
  private readonly searchTerm?: string;

  @ArrayProp(() => [])
  private readonly disabledHealths!: GatewayHealth[];

  @ArrayProp()
  private readonly healthAggregations?: DomainUiGatewaysPanelHealthAggregationFragment[];

  @StringProp('Zustand')
  private readonly healthFilterPlaceholder!: string;

  @BooleanProp()
  private readonly empty!: boolean;

  @NumberProp(1, 0)
  private readonly totalPages!: number;

  @NumberProp(1, 1)
  private readonly currentPage!: number;

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  private get healthOptions(): Option[] {
    const healthMap = this.healthAggregations === undefined ? {} : keyBy(this.healthAggregations, 'health');
    const disabledHealthSet = new Set(this.disabledHealths);

    return Object.values(GATEWAY_HEALTH_META)
      .filter(({ value }) => !disabledHealthSet.has(value))
      .map(({ value, label }) => {
        const count = healthMap[value]?.count ?? 0;

        return { value, label: `${capitalize(label)} (${count})`, count };
      });
  }
}
