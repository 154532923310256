




































import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp, BooleanProp, DateProp, EnumProp, StringProp } from '@/util/prop-decorators';
import { TreeNodeIdFilterStrategy } from '@/types/iot-portal';
import { HeatConsumptionRole, PropertyTreeNodeAttribute } from '@/features/domain-ui/eed-consumption/model';
import ConsumptionWidget from '@/features/domain-ui/eed-consumption/consumption-widget/ConsumptionWidget.vue';
import CurrentMonthPreviousYearConsumptionComparisonWidget from '@/features/domain-ui/eed-consumption/consumption-comparison/CurrentMonthPreviousYearConsumptionComparisonWidget.vue';

@Component({
  components: {
    ConsumptionWidget,
    CurrentMonthPreviousYearConsumptionComparisonWidget,
  },
})
export default class HeatConsumption extends Vue {
  @StringProp()
  private readonly treeNodeId!: string;

  @DateProp()
  private readonly period!: Date;

  @ArrayProp()
  private readonly attributes!: PropertyTreeNodeAttribute[];

  @ArrayProp(true)
  private readonly roles!: HeatConsumptionRole[];

  @BooleanProp(false)
  private readonly hasCurrentMonthPreviousYearConsumptionComparisonWidget!: boolean;

  @BooleanProp(false)
  private readonly hasCustomWrapper!: boolean;

  @EnumProp(TreeNodeIdFilterStrategy.PARENT, TreeNodeIdFilterStrategy.ANCESTOR_OR_SELF)
  private readonly filterStrategy!: TreeNodeIdFilterStrategy;

  @StringProp()
  private readonly label?: string;
}
