var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[(_vm.empty)?_c('ui-cell',{attrs:{"basis":1}},[_vm._t("empty",function(){return [_c('ui-form-message',[_vm._v("In diesem Objekt sind keine Gateways installiert")])]})],2):[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',[_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-text',{attrs:{"data-element-id":'gateway--list-search-field',"value":_vm.searchTerm,"placeholder":'Identifikatoren suchen',"disabled":_vm.loading},on:{"update":function($event){return _vm.$emit('update:searchTerm', $event)}}})],1),_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"data-element-id":'gateway-list-health-select',"value":_vm.health,"options":_vm.healthOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":_vm.healthFilterPlaceholder,"selectable":function (ref) {
	var count = ref.count;

	return count > 0;
},"disabled":_vm.loading},on:{"update":function($event){return _vm.$emit('update:health', $event)}}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_vm._t("right")],2)],1)],1)]],2),(_vm.gateways.length > 0 || _vm.loading)?[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":4,"line-count":2,"header-line-count":2},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('domain-ui-gateway-table',{attrs:{"gateways":_vm.gateways,"disabled-columns":_vm.disabledColumns},on:{"gateway-click":function($event){return _vm.$emit('gateway-click', $event)}}})]},proxy:true}],null,false,2681374075)})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"value":_vm.currentPage,"total-pages":_vm.totalPages},on:{"input":function($event){return _vm.$emit('update:currentPage', $event)}}})],1):_vm._e()]:_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Gateways entsprechen dem eingestellten Filter")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }