














import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { MetricPoint } from '@/features/core/util/metrics';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { SpotTableRow } from './model';

@Component
export default class PrimaryMetric extends Mixins(DeviceRoleMapMixin) {
  @ObjectProp(true)
  private readonly row!: SpotTableRow;

  private get primaryMetricName(): string | undefined {
    return this.deviceRoleMap[this.row.spot.role]?.defaultMetricNames?.[0];
  }

  private get point(): MetricPoint | undefined {
    return this.row.metrics.all[this.primaryMetricName ?? ''];
  }
}
