











import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiAttributeFragment } from './__generated__/DomainUiAttributeFragment';

@Component
export default class Attribute extends Vue {
  @ObjectProp()
  private readonly attribute?: DomainUiAttributeFragment;

  public get formattedValue(): string {
    const { attribute } = this;
    if (attribute === undefined) {
      return '–';
    }

    switch (attribute.configuration.__typename) {
      case 'SingleLineTextAttributeConfiguration':
      case 'SelectAttributeConfiguration':
      case 'CalculationAttributeConfiguration':
        return attribute.value === '' ? '–' : attribute.value;
      case 'CheckboxAttributeConfiguration':
        return attribute.value === '' ? 'Nein' : 'Ja';
    }
  }
}
