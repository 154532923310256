






import { BooleanProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AttributeConfigurationTypeMeta, ATTRIBUTE_CONFIGURATION_TYPE_META } from './constants';

@Component
export default class AttributeConfigurationType extends Vue {
  @EnumProp(...Object.keys(ATTRIBUTE_CONFIGURATION_TYPE_META))
  private readonly type!: import('./constants').AttributeConfigurationType;

  @BooleanProp()
  private readonly inline!: boolean;

  private get meta(): AttributeConfigurationTypeMeta {
    return ATTRIBUTE_CONFIGURATION_TYPE_META[this.type];
  }
}
