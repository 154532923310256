import { VUE_APP_NODE_ENV } from '@/env';

export function generateBookmarkTokenUrl(token: string): string {
  switch (VUE_APP_NODE_ENV) {
    case 'sales':
      return `https://sales.gk4null.de/apc/${token}`;
    case 'production':
      return `https://gk4null.de/apc/${token}`;
    default:
      return `${window.location.origin}/apc/${token}`;
  }
}

export function generateActivityCheckInAuthTokenUrl(token: string): string {
  switch (VUE_APP_NODE_ENV) {
    case 'sales':
      return `https://sales.gk4null.de/apc/auth-token/${token}`;
    case 'production':
      return `https://gk4null.de/apc/auth-token/${token}`;
    default:
      return `${window.location.origin}/apc/auth-token/${token}`;
  }
}
