
















import MiniSensor from '@/components/mini-sensor/MiniSensor.vue';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { BooleanProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { SpotInfoSpot } from './model';

@Component({ components: { MiniSensor } })
export default class SpotInfo extends Mixins(DeviceRoleMapMixin) {
  @ObjectProp()
  private readonly spot?: SpotInfoSpot;

  @StringProp('–')
  private readonly emptyMessage!: string;

  @BooleanProp()
  private readonly disabled!: boolean;

  private get serial(): string | undefined {
    return this.spot?.activeDeviceMount?.serial ?? '–';
  }
}
