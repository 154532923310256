import { render, staticRenderFns } from "./InputAttributeFilter.global.vue?vue&type=template&id=5df6b634&lang=pug&"
import script from "./InputAttributeFilter.global.vue?vue&type=script&lang=ts&"
export * from "./InputAttributeFilter.global.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports