















import { Component, Mixins } from 'vue-property-decorator';
import { ConsumptionMixin } from '../mixins/consumption-mixin';
import CurrentMonthPreviousYearConsumptionComparison from './CurrentMonthPreviousYearConsumptionComparison.vue';

@Component({
  components: {
    CurrentMonthPreviousYearConsumptionComparison,
  },
})
export default class CurrentMonthPreviousYearConsumptionComparisonWidget extends Mixins(ConsumptionMixin) {}
