import {
  ConsumptionRole,
  ConsumptionSpots,
  DateUnit,
  PeriodType,
  PropertyTreeNodeAttribute,
  Unit,
} from '@/features/domain-ui/eed-consumption/model';
import {
  MetricDescriptorInput,
  MetricResolutionAggregator,
  MetricResolutionFill,
  MetricResolutionInput,
  MetricResolutionTimeSource,
  SpotRole,
  TreeNodeIdFilterStrategy,
} from '@/types/iot-portal';
import { ArrayProp, DateProp, EnumProp, StringProp } from '@/util/prop-decorators';
import { Dictionary, flatten, groupBy, isEmpty, isUndefined, max, uniq } from 'lodash';
import moment from 'moment-timezone';
import numeral from '@/util/numeral-locale';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CONSUMPTION_MIXIN_META } from './constants';
import query from './consumption-mixin.gql';
import userAccess from './user-access-date.gql';
import { calculateConsumption, calculateConsumptionDiff } from './util';
import {
  AppEEDConsumptionMixinQuery,
  AppEEDConsumptionMixinQueryVariables,
} from './__generated__/AppEEDConsumptionMixinQuery';
import { AppEEDUserAccessDateQuery } from './__generated__/AppEEDUserAccessDateQuery';
@Component({
  apollo: {
    spots: {
      query,
      fetchPolicy: 'no-cache',
      skip(this: ConsumptionMixin): boolean {
        switch (this.periodType) {
          case PeriodType.CURRENT_MONTH_PREVIOUS_YEAR:
            if (
              moment(this.userAccessDate).toDate() >=
              moment(this.period).subtract(1, DateUnit.YEAR).startOf(DateUnit.MONTH).toDate()
            ) {
              this.$emit('start-date-current-month-previous-year');
              this.$emit('end-date-current-month-previous-year');
              return true;
            }
            return false;
          case PeriodType.PREVIOUS_MONTH:
            if (
              moment(this.userAccessDate).toDate() >=
              moment(this.period).subtract(1, DateUnit.MONTH).startOf(DateUnit.MONTH).toDate()
            ) {
              this.$emit('start-date-previous-month');
              this.$emit('end-date-previous-month');
              return true;
            }
            return false;
          default:
            return false;
        }
      },
      variables(this: ConsumptionMixin): AppEEDConsumptionMixinQueryVariables {
        return {
          treeNodeId: this.treeNodeId,
          descriptors: this.descriptors,
          beforeDescriptors: this.withBeforeMetrics ? this.beforeDescriptors : undefined,
          checkableDescriptors: this.checkableDescriptors,
          attributesNames: this.attributesNames,
          idFilterStrategy: this.filterStrategy,
          roles: this.roles,
          withPoints: this.withPoints,
          checkableMetric: this.checkableMetric,
        };
      },
    },
    userAccess: {
      query: userAccess,
      fetchPolicy: 'cache-and-network',
      manual: true,
      result(this: ConsumptionMixin, { data }: { data: AppEEDUserAccessDateQuery }) {
        if (data && data.me) {
          this.flatAccess = data.me.flatAccess;
        }
      },
    },
  },
  data() {
    return {
      spots: undefined,
      periodType: PeriodType.CURRENT_MONTH,
      aggregationInterval: undefined,
      aggregator: undefined,
      withPoints: false,
      checkableMetric: false,
      withBeforeMetrics: true,
      flatAccess: undefined,
    };
  },
})
export class ConsumptionMixin extends Vue {
  @StringProp()
  protected readonly treeNodeId!: string;

  @DateProp()
  protected readonly period!: Date;

  @ArrayProp()
  protected readonly attributes!: PropertyTreeNodeAttribute[];

  @ArrayProp(() => [])
  protected declare readonly roles: ConsumptionRole[];

  @EnumProp(TreeNodeIdFilterStrategy.PARENT, TreeNodeIdFilterStrategy.ANCESTOR_OR_SELF)
  protected readonly filterStrategy!: TreeNodeIdFilterStrategy;

  protected readonly periodType!: PeriodType;

  protected readonly aggregationInterval?: string;

  protected readonly aggregator?: MetricResolutionAggregator;

  protected readonly withPoints!: boolean;

  protected readonly checkableMetric!: boolean;

  protected withBeforeMetrics!: boolean;

  private spots?: ConsumptionSpots;

  private flatAccess?: AppEEDUserAccessDateQuery['me']['flatAccess'];

  private readonly timezone: string = 'Europe/Berlin';

  @Watch('spots')
  protected emitLastUpdate(): void {
    if (!this.spots) {
      return;
    }

    const { periodType } = this;

    const lastMetricUpdate = max(
      this.spots.items
        .map((spot) =>
          spot.metrics.map((metric) => (metric.__typename === 'ContinuousMetric' ? metric.latest.time : '')),
        )
        .flat(),
    );

    const lastBeforeMetricUpdate = max(
      this.spots.items
        .map((spot) =>
          spot.beforeMetrics.map((metric) => (metric.__typename === 'ContinuousMetric' ? metric.latest.time : '')),
        )
        .flat(),
    );

    switch (periodType) {
      case PeriodType.CURRENT_MONTH:
        this.$emit('start-date-current-month', lastBeforeMetricUpdate);
        this.$emit('end-date-current-month', lastMetricUpdate);
        break;
      case PeriodType.PREVIOUS_MONTH:
        this.$emit('start-date-previous-month', lastBeforeMetricUpdate);
        this.$emit('end-date-previous-month', lastMetricUpdate);
        break;
      case PeriodType.CURRENT_MONTH_PREVIOUS_YEAR:
        this.$emit('start-date-current-month-previous-year', lastBeforeMetricUpdate);
        this.$emit('end-date-current-month-previous-year', lastMetricUpdate);
        break;
      case PeriodType.CURRENT_YEAR_UNTIL_PREVIOUS_MONTH:
      case PeriodType.CURRENT_YEAR:
        this.$emit('last-update', lastMetricUpdate);
        break;
    }
  }

  private get shouldBeShown(): boolean {
    if (this.checkableMetric) {
      const role: SpotRole | undefined = this.roles[0];
      if (role === undefined) {
        return false;
      }
      if (!this.groupedSpots) {
        return false;
      }
      if (this.groupedSpots[role].every((item) => item.metrics.length > 0)) {
        return this.groupedSpots[role].every((item) => item.checkableMetric.length > 0);
      }
    }
    return true;
  }

  private get checkableDescriptors(): MetricDescriptorInput[] {
    if (!this.checkableMetric) {
      return [];
    }

    const { period, periodType } = this;

    let startDate: string | undefined, stopDate: string | undefined;

    moment.tz.setDefault(this.timezone);

    switch (periodType) {
      case PeriodType.CURRENT_MONTH:
        startDate = moment(period).subtract(1, DateUnit.MONTH).startOf(DateUnit.MONTH).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.MONTH).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.PREVIOUS_MONTH:
        startDate = moment(period).subtract(2, DateUnit.MONTH).startOf(DateUnit.MONTH).toISOString();
        stopDate = moment(period).subtract(2, DateUnit.MONTH).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.CURRENT_MONTH_PREVIOUS_YEAR:
        startDate = moment(period)
          .subtract(1, DateUnit.YEAR)
          .subtract(1, DateUnit.MONTH)
          .startOf(DateUnit.MONTH)
          .toISOString();
        stopDate = moment(period)
          .subtract(1, DateUnit.YEAR)
          .subtract(1, DateUnit.MONTH)
          .endOf(DateUnit.MONTH)
          .toISOString();
        break;
    }

    return this.roles.map((role) => {
      let metricName = 'currentConsumption';

      switch (role) {
        case SpotRole.WATER_METER_HOT:
          metricName = 'currentVolume';
          break;
        case SpotRole.HEAT_METER_COUNTER:
          metricName = 'currentEnergy';
          break;
      }

      return {
        name: metricName,
        start: startDate,
        stop: stopDate,
      };
    });
  }

  private get descriptors(): MetricDescriptorInput[] {
    const { period, periodType } = this;

    let startDate: string | undefined, stopDate: string | undefined;

    moment.tz.setDefault(this.timezone);

    switch (periodType) {
      case PeriodType.CURRENT_MONTH:
        startDate = moment(period).startOf(DateUnit.MONTH).toISOString();
        stopDate = moment(period).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.PREVIOUS_MONTH:
        startDate = moment(period).subtract(1, DateUnit.MONTH).startOf(DateUnit.MONTH).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.MONTH).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.CURRENT_YEAR:
        startDate = moment(period).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).endOf(DateUnit.YEAR).toISOString();
        break;
      case PeriodType.PREVIOUS_YEAR:
        startDate = moment(period).subtract(1, DateUnit.YEAR).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.YEAR).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.CURRENT_AND_PREVIOUS_YEAR:
        startDate = moment(period).subtract(1, DateUnit.YEAR).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).endOf(DateUnit.YEAR).toISOString();
        break;
      case PeriodType.CURRENT_AND_PREVIOUS_YEAR_PREVIOUS_MONTH:
        startDate = moment(period)
          .subtract(1, DateUnit.YEAR)
          .startOf(DateUnit.YEAR)
          .subtract(1, DateUnit.MONTH)
          .toISOString();
        stopDate = moment(period).endOf(DateUnit.YEAR).toISOString();
        break;
      case PeriodType.CURRENT_MONTH_PREVIOUS_YEAR:
        startDate = moment(period).subtract(1, DateUnit.YEAR).startOf(DateUnit.MONTH).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.YEAR).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.CURRENT_YEAR_UNTIL_PREVIOUS_MONTH:
        startDate = moment(period).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.MONTH).endOf(DateUnit.MONTH).toISOString();
        break;
    }

    return this.roles.map((role) => {
      let metricName = 'currentConsumption';

      switch (role) {
        case SpotRole.WATER_METER_HOT:
          metricName = 'currentVolume';
          break;
        case SpotRole.HEAT_METER_COUNTER:
          metricName = 'currentEnergy';
          break;
      }

      let resolution: MetricResolutionInput | undefined = undefined;

      if (this.aggregationInterval && this.aggregator) {
        resolution = {
          aggregator: this.aggregator,
          intervalLength: this.aggregationInterval,
          fill: MetricResolutionFill.NONE,
          timeSource: MetricResolutionTimeSource.START,
        };
      }

      return {
        name: metricName,
        start: startDate,
        stop: stopDate,
        resolution,
      };
    });
  }

  private get beforeDescriptors(): MetricDescriptorInput[] {
    const { period, periodType } = this;

    let startDate: string | undefined, stopDate: string | undefined;

    moment.tz.setDefault(this.timezone);

    switch (periodType) {
      case PeriodType.CURRENT_MONTH:
        startDate = moment(period).subtract(1, DateUnit.MONTH).startOf(DateUnit.MONTH).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.MONTH).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.PREVIOUS_MONTH:
        startDate = moment(period).subtract(2, DateUnit.MONTH).startOf(DateUnit.MONTH).toISOString();
        stopDate = moment(period).subtract(2, DateUnit.MONTH).endOf(DateUnit.MONTH).toISOString();
        break;
      case PeriodType.CURRENT_YEAR:
        startDate = moment(period).subtract(1, DateUnit.YEAR).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.YEAR).endOf(DateUnit.YEAR).toISOString();
        break;
      case PeriodType.PREVIOUS_YEAR:
        startDate = moment(period).subtract(2, DateUnit.YEAR).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).subtract(2, DateUnit.YEAR).endOf(DateUnit.YEAR).toISOString();
        break;
      case PeriodType.CURRENT_AND_PREVIOUS_YEAR:
        startDate = moment(period).subtract(2, DateUnit.YEAR).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.YEAR).endOf(DateUnit.YEAR).toISOString();
        break;
      case PeriodType.CURRENT_AND_PREVIOUS_YEAR_PREVIOUS_MONTH:
        startDate = moment(period)
          .subtract(2, DateUnit.YEAR)
          .startOf(DateUnit.YEAR)
          .subtract(1, DateUnit.MONTH)
          .toISOString();
        stopDate = moment(period).subtract(1, DateUnit.YEAR).endOf(DateUnit.YEAR).toISOString();
        break;
      case PeriodType.CURRENT_MONTH_PREVIOUS_YEAR:
        startDate = moment(period)
          .subtract(1, DateUnit.YEAR)
          .subtract(1, DateUnit.MONTH)
          .startOf(DateUnit.MONTH)
          .toISOString();
        stopDate = moment(period)
          .subtract(1, DateUnit.YEAR)
          .subtract(1, DateUnit.MONTH)
          .endOf(DateUnit.MONTH)
          .toISOString();
        break;
      case PeriodType.CURRENT_YEAR_UNTIL_PREVIOUS_MONTH:
        startDate = moment(period).subtract(1, DateUnit.YEAR).startOf(DateUnit.YEAR).toISOString();
        stopDate = moment(period).subtract(1, DateUnit.YEAR).endOf(DateUnit.YEAR).toISOString();
        break;
    }

    return this.roles.map((role) => {
      let metricName = 'currentConsumption';

      switch (role) {
        case SpotRole.WATER_METER_HOT:
          metricName = 'currentVolume';
          break;
        case SpotRole.HEAT_METER_COUNTER:
          metricName = 'currentEnergy';
          break;
      }

      return {
        name: metricName,
        start: startDate,
        stop: stopDate,
      };
    });
  }

  protected get groupedSpots(): Dictionary<AppEEDConsumptionMixinQuery['spots']['items']> | undefined {
    if (isUndefined(this.spots) || isEmpty(this.spots)) {
      return undefined;
    }

    const groupedSpotsByRole = groupBy(this.spots.items, (spot) => spot.role);

    return groupedSpotsByRole;
  }

  private get attributesNames(): string[] {
    return uniq(
      flatten(
        this.roles.map((role) => {
          return CONSUMPTION_MIXIN_META[role].attributesNames;
        }),
      ),
    );
  }

  protected get consumption(): number {
    if (isEmpty(this.spots) || isUndefined(this.spots) || isUndefined(this.roles)) {
      return 0.0;
    }
    try {
      const consumptionValue = calculateConsumption(this.spots?.items ?? [], this.attributes);
      return consumptionValue > 0 ? consumptionValue : 0;
    } catch (error) {
      this.$emit('calculation-error');
      throw error;
    }
  }

  protected computePercentageDiff(currentConsumption: number): number {
    try {
      if (isEmpty(this.roles)) throw new Error('No role provided for this function');
      if (Object.keys(this.roles).length > 1) throw new Error('Too many roles provided for this function');
      return calculateConsumptionDiff(this.spots?.items ?? [], currentConsumption, this.attributes);
    } catch (error) {
      this.$emit('calculation-error');
      throw error;
    }
  }

  protected formatConsumption(value: number): string {
    return numeral(value).format('0.0,0[00]');
  }

  protected get unit(): Unit | undefined {
    return CONSUMPTION_MIXIN_META[this.roles[0]]?.unit;
  }

  protected get userAccessDate(): Date {
    const userAccessDate = this.flatAccess?.accessDate;
    const startOfYear2021 = moment().year(2021).startOf(DateUnit.YEAR);
    if (userAccessDate) {
      return moment(userAccessDate).utc().toDate() >= startOfYear2021.utc().toDate()
        ? moment(userAccessDate).utc().toDate()
        : startOfYear2021.utc().toDate();
    }
    return startOfYear2021.utc().toDate();
  }
}
