














import MiniSensor from '@/components/mini-sensor/MiniSensor.vue';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import heatingSystemImage from './heating-system.svg';
import { HeatingSystemInfoHeatingSystem } from './model';

@Component({ components: { MiniSensor } })
export default class SpotInfo extends Mixins(DeviceRoleMapMixin) {
  private readonly heatingSystemImage = heatingSystemImage;

  @ObjectProp()
  private readonly heatingSystem?: HeatingSystemInfoHeatingSystem;
}
