






















import MiniSensor from '@/components/mini-sensor/MiniSensor.vue';
import { BooleanProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { GatewayInfoGateway } from './model';

@Component({ components: { MiniSensor } })
export default class GatewayInfo extends Vue {
  @ObjectProp()
  private readonly gateway?: GatewayInfoGateway;

  @StringProp('–')
  private readonly emptyMessage!: string;

  @BooleanProp()
  private readonly disabled!: boolean;
}
