




































import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp, ObjectProp } from '@/util/prop-decorators';
import { DomainUiPropertyTablePropertyFragment } from './__generated__/DomainUiPropertyTablePropertyFragment';
import { TreeNodePathTreeNode } from '../tree-node-path/model';

@Component
export default class PropertyTable extends Vue {
  @ArrayProp(() => [])
  private readonly properties!: DomainUiPropertyTablePropertyFragment[];

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  private readonly columns = [
    { name: 'path', label: 'Zuordnung' },
    { name: 'name', label: 'Objekt / ID Vodafone • ID Kunde' },
    // { name: 'directoryExternalId', field: 'parent.meta.externalId', label: 'Objekt ID Kunde' },
    { name: 'address', label: 'Adresse' },
    { name: 'subdivisionCount', field: 'propertySubdivisions.count', label: '# Untereinheiten' },
    { name: 'technicalContact', label: 'Technischer Kontakt' },
    { name: 'actions', label: '' },
  ];
}
