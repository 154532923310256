



























import { GATEWAY_HEALTH_META } from '@/features/domain-ui/gateway-health/constants';
import { Point } from '@/features/ui/charts/stacked-horizontal-chart/model';
import { GatewayHealth } from '@/types/iot-portal';
import { BooleanProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiGatewaySummaryPanelCollectionFragment } from './__generated__/DomainUiGatewaySummaryPanelCollectionFragment';

const gatewayHealths = [GatewayHealth.CRITICAL, GatewayHealth.DEGRADED, GatewayHealth.OK, GatewayHealth.UNKNOWN];

type HealthState = { health: GatewayHealth; count: number };

@Component
export default class GatewaySummaryPanel extends Vue {
  @ObjectProp(true)
  private readonly collection!: DomainUiGatewaySummaryPanelCollectionFragment;

  @BooleanProp()
  private readonly loading!: boolean;

  private get healthStates(): HealthState[] {
    const stateMap = new Map(this.collection.healthAggregations.map(({ health, count }) => [health, count]));

    return gatewayHealths.map((health) => ({ health, count: stateMap.get(health) ?? 0 }));
  }

  private get chartPoints(): Point[] {
    return this.healthStates.map(({ count, health }) => ({ value: count, color: GATEWAY_HEALTH_META[health].color }));
  }
}
