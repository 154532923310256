var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"enabled-columns":_vm.enabledColumns,"disabled-columns":_vm.disabledColumns,"hidden-columns":_vm.hiddenColumns,"rows":_vm.alerts,"headless":_vm.headless},scopedSlots:_vm._u([{key:"path",fn:function(ref){
var row = ref.row;
return [(row.spot === null)?[_vm._v("–")]:_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.spot.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"spot",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-spot-info',{attrs:{"spot":row.spot || undefined},on:{"spot-click":function($event){return _vm.$emit('click', $event)}}})]}},{key:"category.label",fn:function(){return [_vm._v("Regel"),_c('br'),_vm._v("Kategorie")]},proxy:true},{key:"category",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_c('domain-ui-alert-category',{attrs:{"category":row.category,"inline":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(row.ruleDeployment === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.ruleDeployment.name)),(row.ruleDeployment.deletionDate !== null)?[_vm._v(_vm._s(' ')),_c('em',[_vm._v("(gelöscht)")])]:_vm._e()]]},proxy:true}],null,true)}),_c('br'),_c('domain-ui-alert-category',{attrs:{"category":row.category,"inline":""}})],1)]}},{key:"date.label",fn:function(){return [_vm._v("Beginn"),_c('br'),_vm._v("Ende")]},proxy:true},{key:"date",fn:function(ref){
var row = ref.row;
return [_c('ui-relative-time',{attrs:{"date":row.startDate,"min":"-PT30M","format":"L LT"}}),_c('br'),_c('ui-relative-time',{class:{ ongoing: row.endDate === null },attrs:{"date":row.endDate,"empty-message":"Aktiv","min":"-PT30M","format":_vm.isSameDay(row.startDate, row.endDate) ? 'LT' : 'L LT'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
return [_vm._v(_vm._s(displayDate.replace('seit', 'vor')))]}}],null,true)})]}},_vm._l((_vm.$scopedSlots),function(slot,name){return {key:name,fn:function(props){return [_vm._t(name,null,null,props)]}}}),{key:"reset",fn:function(ref){
var row = ref.row;
return [(row.endDate === null)?_c('ui-link',{on:{"click":function($event){return _vm.$emit('reset-alert', row)}}},[_vm._v("Beenden")]):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }