





























import { Component, Vue } from 'vue-property-decorator';
import { ObjectProp } from '@/util/prop-decorators';
import { Measurement } from '@/features/app-heating-system/components/heating-system-measurement/Measurement.vue';

@Component
export default class DhwTemperatureWidget extends Vue {
  @ObjectProp()
  private readonly heatingTemperatureMain?: Measurement;

  @ObjectProp()
  private readonly hotWaterStorageTemperature?: Measurement;

  private get heatingTemperatureMainValue(): number | undefined {
    return this.heatingTemperatureMain?.latestMetricPoint?.value;
  }

  private get hotWaterStorageTemperatureValue(): number | undefined {
    return this.hotWaterStorageTemperature?.latestMetricPoint?.value;
  }
}
