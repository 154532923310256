













import { BooleanProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { SpotHealthMeta, SPOT_HEALTH_META } from './constants';

@Component
export default class SpotHealth extends Vue {
  @EnumProp(...Object.keys(SPOT_HEALTH_META))
  private readonly health!: import('@/types/iot-portal').SpotHealth;

  @BooleanProp()
  private readonly capitalize!: boolean;

  @BooleanProp()
  private readonly obsolete!: boolean;

  @BooleanProp()
  private readonly inline!: boolean;

  @BooleanProp()
  private readonly noTooltip!: boolean;

  private get meta(): SpotHealthMeta {
    return SPOT_HEALTH_META[this.health];
  }

  private get style(): Record<string, string | undefined> {
    return {
      display: this.inline ? 'inline' : undefined,
      'text-transform': this.capitalize ? 'capitalize' : undefined,
      'text-decoration': this.obsolete ? 'line-through' : undefined,
      color: this.meta.color,
    };
  }
}
