
































import { Option } from '@/features/ui/inputs/model';
import { ArrayProp, BooleanProp, NumberProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiAlertTableAlertFragment } from '../alert-table/__generated__/DomainUiAlertTableAlertFragment';

@Component
export default class AlertsPanel extends Vue {
  @ArrayProp(() => [])
  private readonly alerts!: DomainUiAlertTableAlertFragment[];

  @BooleanProp()
  private readonly loading!: boolean;

  @BooleanProp()
  private readonly activeOnly!: boolean;

  @BooleanProp()
  private readonly filterActiveOnly!: boolean;

  @NumberProp(1, 0)
  private readonly totalPages!: number;

  @NumberProp(1, 1)
  private readonly currentPage!: number;

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  private activeOptions: Option[] = [
    { value: true, label: 'Aktive' },
    { value: false, label: 'Alle' },
  ];
}
