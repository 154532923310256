


































import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp, BooleanProp, DateProp, EnumProp, StringProp } from '@/util/prop-decorators';
import { SpotRole, TreeNodeIdFilterStrategy } from '@/types/iot-portal';
import { PropertyTreeNodeAttribute } from '@/features/domain-ui/eed-consumption/model';
import ConsumptionWidget from '@/features/domain-ui/eed-consumption/consumption-widget/ConsumptionWidget.vue';
import CurrentMonthPreviousYearConsumptionComparisonWidget from '@/features/domain-ui/eed-consumption/consumption-comparison/CurrentMonthPreviousYearConsumptionComparisonWidget.vue';

@Component({
  components: {
    ConsumptionWidget,
    CurrentMonthPreviousYearConsumptionComparisonWidget,
  },
})
export default class WaterConsumption extends Vue {
  @StringProp()
  private readonly treeNodeId!: string;

  @DateProp()
  private readonly period!: Date;

  @ArrayProp()
  private readonly attributes!: PropertyTreeNodeAttribute[];

  @BooleanProp(false)
  private readonly hasCurrentMonthPreviousYearConsumptionComparisonWidget!: boolean;

  @BooleanProp(false)
  private readonly hasCustomWrapper!: boolean;

  @EnumProp(TreeNodeIdFilterStrategy.PARENT, TreeNodeIdFilterStrategy.ANCESTOR_OR_SELF)
  private readonly filterStrategy!: TreeNodeIdFilterStrategy;

  private readonly role = SpotRole.WATER_METER_HOT;
}
