









































































































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import ManufacturerMapMixin from '@/features/core/components/mixins/manufacturer-map';
import { SPOT_HEALTH_META } from '@/features/domain-ui/spot-health/constants';
import { SpotTableData, SpotTableSpot } from '@/features/domain-ui/spot-table/model';
import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { DatePreset, Option } from '@/features/ui/inputs/model';
import { SpotHealth, TreeNodeType } from '@/types/iot-portal';
import {
  ArrayProp,
  BooleanProp,
  FunctionProp,
  NumberProp,
  ObjectProp,
  OptionalProp,
  StringProp,
} from '@/util/prop-decorators';
import { capitalize, difference, intersection, keyBy, startCase } from 'lodash';
import moment, { Moment, MomentInput } from 'moment';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { SpotsExportSpot } from '../spots-export/model';
import { AttributeFilter, CONSUMPTION_ROLES } from './model';
import { DomainUiSpotsPanelAttributeFragment } from './__generated__/DomainUiSpotsPanelAttributeFragment';
import { DomainUiSpotsPanelHealthAggregationFragment } from './__generated__/DomainUiSpotsPanelHealthAggregationFragment';
import { DomainUiSpotsPanelManufacturerAggregationFragment } from './__generated__/DomainUiSpotsPanelManufacturerAggregationFragment';
import { DomainUiSpotsPanelRoleAggregationFragment } from './__generated__/DomainUiSpotsPanelRoleAggregationFragment';

@Component({
  data() {
    return { exportedSpots: undefined };
  },
})
export default class SpotsPanel extends Mixins(DeviceRoleMapMixin, ManufacturerMapMixin) {
  @BooleanProp()
  private readonly loading!: boolean;

  @BooleanProp()
  private readonly empty!: boolean;

  @ArrayProp(() => [])
  private readonly spots!: SpotTableSpot[];

  @FunctionProp()
  private readonly exportSpots?: () => Promise<SpotsExportSpot[]>;

  @BooleanProp()
  private readonly roleFilter!: boolean;

  @StringProp()
  private readonly role?: string;

  @ArrayProp()
  private readonly roleAggregations?: DomainUiSpotsPanelRoleAggregationFragment[];

  @BooleanProp()
  private readonly healthFilter!: boolean;

  @StringProp()
  private readonly health?: SpotHealth;

  @ArrayProp(() => [])
  private readonly disabledHealths!: SpotHealth[];

  @ArrayProp()
  private readonly healthAggregations?: DomainUiSpotsPanelHealthAggregationFragment[];

  @BooleanProp()
  private readonly manufacturerFilter!: boolean;

  @StringProp()
  private readonly manufacturer?: string;

  @ArrayProp()
  private readonly manufacturerAggregations?: DomainUiSpotsPanelManufacturerAggregationFragment[];

  @ObjectProp(() => ({}))
  private readonly attributeFilter!: AttributeFilter;

  @ArrayProp(() => [])
  private readonly attributes!: DomainUiSpotsPanelAttributeFragment[];

  @BooleanProp()
  private readonly metricsStopDateFilter!: boolean;

  @OptionalProp()
  private readonly metricsStopDate?: Exclude<MomentInput, void>;

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  @NumberProp(1, 0)
  private readonly totalPages!: number;

  @NumberProp(1, 1)
  private readonly currentPage!: number;

  private readonly metricsStopDatePresets: DatePreset[] = [
    { label: 'Aktuell', date: () => undefined },
    {
      label: 'Letzter Monat',
      date: (now) => now().subtract(1, 'month').endOf('month'),
    },
    {
      label: 'Letztes Jahr',
      date: (now) => now().subtract(1, 'year').endOf('year'),
    },
  ];

  private hiddenMetricColumns: string[] = [];
  private shownAttributeColumns: string[] = [];
  private hiddenBaseColumns: string[] = [];
  private exportedSpots?: SpotsExportSpot[];
  private exporting = 0;
  private showAttributeFilter = false;

  private get tableData(): SpotTableData {
    return SpotTableData.from(this.spots);
  }

  private get roleOptions(): Option[] {
    const aggregations = this.roleAggregations ?? [];
    if (this.role !== undefined && aggregations.find(({ role }) => role === this.role) === undefined) {
      aggregations.push({
        __typename: 'SpotRoleAggregation',
        role: this.role,
        count: 0,
      });
    }

    const consumptionSpotCount = aggregations
      .filter(({ role }) => this.deviceRoleMap[role]?.consumption ?? false)
      .map(({ count }) => count)
      .reduce((a, b) => a + b, 0);

    return [
      {
        value: CONSUMPTION_ROLES,
        label: `Verbrauchszähler (${consumptionSpotCount})`,
        count: consumptionSpotCount,
      } as Option,
      ...aggregations
        .map(({ role, count }) => {
          const label = this.deviceRoleMap[role]?.label ?? startCase(role);

          return { value: role, label: `${label} (${count})`, count };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }

  private get healthOptions(): Option[] {
    const healthMap = this.healthAggregations === undefined ? {} : keyBy(this.healthAggregations, 'health');
    const disabledHealthSet = new Set(this.disabledHealths);

    return Object.values(SPOT_HEALTH_META)
      .filter(({ value }) => !disabledHealthSet.has(value))
      .map(({ value, label }) => {
        const count = healthMap[value]?.count ?? 0;

        return { value, label: `${capitalize(label)} (${count})`, count };
      });
  }

  private get manufacturerOptions(): Option[] {
    const aggregations = this.manufacturerAggregations ?? [];
    if (
      this.manufacturer !== undefined &&
      aggregations.find(({ manufacturer }) => manufacturer === this.manufacturer) === undefined
    ) {
      aggregations.push({
        __typename: 'SpotManufacturerAggregation',
        manufacturer: this.manufacturer,
        count: 0,
      });
    }

    return aggregations
      .map(({ manufacturer, count }) => {
        const label = manufacturer === 'UNKNOWN' ? 'Unbekannt' : manufacturer;

        return { value: manufacturer, label: `${label} (${count})`, count };
      })
      .sort((a, b) => Number(a.value === 'UNKNOWN') - Number(b.value === 'UNKNOWN') || a.label.localeCompare(b.label));
  }

  private get spotAttributes(): DomainUiSpotsPanelAttributeFragment[] {
    return this.attributes
      .filter(({ configuration }) => configuration.treeNodeTypes.includes(TreeNodeType.Spot))
      .sort((a, b) => a.configuration.name.localeCompare(b.configuration.name));
  }

  private get hiddenColumns(): string[] {
    return [
      ...this.hiddenMetricColumns,
      ...difference(this.tableData.attributeColumnNames, this.shownAttributeColumns),
      ...this.hiddenBaseColumns,
    ];
  }

  private set hiddenColumns(value: string[]) {
    this.hiddenMetricColumns = intersection(value, this.tableData.metricColumnNames);
    this.shownAttributeColumns = difference(this.tableData.attributeColumnNames, value);
    this.hiddenBaseColumns = difference(value, this.tableData.metricColumnNames, this.tableData.attributeColumnNames);
  }

  @Watch('attributeFilter', { deep: true, immediate: true })
  private updateShowAttributeFilter(filter: AttributeFilter): void {
    this.showAttributeFilter ||= Object.entries(filter).length > 0;
  }

  @Watch('showAttributeFilter')
  private resetAttributeFilter(show: boolean): void {
    if (!show) {
      this.$emit('update:attributeFilter', {});
    }
  }

  @Watch('role')
  private updateHiddenColumns(value: string | undefined): void {
    this.hiddenMetricColumns = value === undefined ? this.tableData.metricColumnNames : [];
  }

  @Watch('tableData', { immediate: true })
  private cleanHiddenColumns(value: SpotTableData, old?: SpotTableData): void {
    if (this.role === undefined) {
      const newColumns = difference(value.metricColumnNames, old?.metricColumnNames ?? []);
      this.hiddenMetricColumns = [...intersection(this.hiddenMetricColumns, value.metricColumnNames), ...newColumns];
    }
  }

  @Watch('exportSpots')
  private resetExportedSpots(): void {
    this.exportedSpots = undefined;
  }

  private updateAttribute(attributeDefinitionId: string, value: string | undefined): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- easy omission
    const { [attributeDefinitionId]: oldValue, ...attributeFilter } = this.attributeFilter ?? {};

    this.$emit(
      'update:attributeFilter',
      value === undefined ? attributeFilter : { ...attributeFilter, [attributeDefinitionId]: value },
    );
  }

  private async loadExportedSpots(): Promise<void> {
    const { exportSpots } = this;
    if (exportSpots === undefined) {
      return;
    }

    this.exporting++;
    const exportedSpots = await exportSpots();
    this.exporting--;

    if (this.exportSpots === exportSpots) {
      this.exportedSpots = exportedSpots;
    }
  }

  private now(): Moment {
    return moment();
  }
}
