














import { OptionalProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MetricPoint extends Vue {
  @OptionalProp()
  private readonly point?: import('@/features/core/util/metrics').MetricPoint;
}
