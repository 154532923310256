



















import { ArrayProp, ObjectProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { getPrimaryTreeNode, getSecondaryTreeNode } from './util';
import { DomainUiTreeNodePathTreeNodeFragment } from './__generated__/DomainUiTreeNodePathTreeNodeFragment';

@Component
export default class TreeNodePath extends Vue {
  @ArrayProp(() => [])
  private readonly treeNodes!: DomainUiTreeNodePathTreeNodeFragment[];

  @ObjectProp()
  private readonly contextTreeNode?: DomainUiTreeNodePathTreeNodeFragment;

  @BooleanProp()
  private readonly neverEmpty!: boolean;

  @BooleanProp()
  private readonly hideSecondaryTreeNode!: boolean;

  private get primaryTreeNode(): DomainUiTreeNodePathTreeNodeFragment | undefined {
    return getPrimaryTreeNode(this.treeNodes, this.contextTreeNode);
  }

  private get secondaryTreeNode(): DomainUiTreeNodePathTreeNodeFragment | undefined {
    return getSecondaryTreeNode(this.treeNodes, this.primaryTreeNode);
  }

  private emitTreeNodeClick(event?: Event, treeNode?: DomainUiTreeNodePathTreeNodeFragment): void {
    this.$emit('tree-node-click', treeNode ?? this.secondaryTreeNode ?? this.primaryTreeNode);
  }
}
