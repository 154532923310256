var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.notifications,"disabled-columns":_vm.disabledColumns,"render-slices":3},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var row = ref.row;
return [_c('ui-form',{attrs:{"action":function () { return _vm.executeChangeReadAction(row); }},on:{"submitted":function($event){return _vm.$emit('read-changed', row)},"error":function($event){return _vm.$emit('error', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
return [_c('ui-clickable',{attrs:{"disabled":!_vm.changeReadAction || busy},on:{"click":submit}},[_c('ui-tooltip',{attrs:{"hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-flex',{attrs:{"items":"center","nowrap":""}},[_c('ui-cell',{attrs:{"grow":0}},[_c('ui-icon-read-indicator',{attrs:{"read":row.read}})],1),_c('ui-cell',{staticClass:"text-overflow"},[_c('ui-relative-time',{staticStyle:{"white-space":"nowrap"},attrs:{"date":row.creationDate,"min":"-PT12H"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
var formattedDate = ref.formattedDate;
var momentDate = ref.momentDate;
var inRange = ref.inRange;
return [_vm._v(_vm._s(formattedDate)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(inRange ? displayDate : momentDate.format('LT')))])]}}],null,true)})],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('ui-date',{attrs:{"date":row.creationDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Eingegangen am "+_vm._s(formattedDate))]}}],null,true)}),_c('br'),(row.read)?[_c('ui-date',{attrs:{"date":row.readingDate,"format":"LLL","empty-message":"Gelesen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Gelesen am "+_vm._s(formattedDate))]}}],null,true)})]:_vm._e(),(_vm.changeReadAction)?[_c('br'),_vm._v("Als "+_vm._s(row.read ? 'ungelesen' : 'gelesen')+" markieren")]:_vm._e()]},proxy:true}],null,true)})],1)]}}],null,true)})]}},{key:"location",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'GatewayHealthNotification')?[_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.gateway.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]:(row.__typename === 'SpotHealthNotification')?[_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.spot.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]:(row.__typename === 'AlertNotification' && row.alert.spot !== null)?[_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.alert.spot.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]:(row.__typename === 'HeatingSystemNotification' && row.heatingSystem !== null && row.heatingSystem.site !== null)?[_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.heatingSystem.site.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]:(row.__typename === 'MissedActivityLogEntryNotification')?[_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.logEntry.treeNode.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]:[_vm._v("–")]]}},{key:"subject",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'AlertNotification')?[_c('domain-ui-spot-info',{attrs:{"spot":row.alert.spot || undefined}})]:(row.__typename === 'GatewayHealthNotification')?[_c('domain-ui-gateway-info',{attrs:{"gateway":row.gateway}})]:(row.__typename === 'SpotHealthNotification')?[_c('domain-ui-spot-info',{attrs:{"spot":row.spot}})]:(row.__typename === 'HeatingSystemNotification')?[_c('domain-ui-heating-system-info',{attrs:{"heating-system":row.heatingSystem},on:{"click":function($event){return _vm.$emit('heating-system-click', row.heatingSystem)}}})]:(row.__typename === 'MissedActivityLogEntryNotification')?[_vm._v(_vm._s(row.logEntry.title)),_c('br'),_c('span',{staticClass:"text-light"},[(row.logEntry.contractor === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.logEntry.contractor))]],2)]:[_vm._v("–")]]}},{key:"details",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'AlertNotification')?[_c('ui-tooltip',{attrs:{"hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('domain-ui-alert-category',{attrs:{"category":row.alert.category},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var name = ref.name;
return [_c('div',{staticClass:"text-overflow",class:{ obsolete: row.alert.endDate !== null }},[(row.alert.ruleDeployment === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.alert.ruleDeployment.name)),(row.alert.ruleDeployment.deletionDate !== null)?[_c('em',[_vm._v("(gelöscht)")])]:_vm._e()],_c('br'),_vm._v(_vm._s(name))],2)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-overflow",class:{ obsolete: row.alert.endDate !== null }},[_vm._v(_vm._s(row.alert.ruleDeployment === null ? '–' : row.alert.ruleDeployment.name)),_c('br'),_vm._v("–")])]},proxy:true}],null,true)})]},proxy:true},{key:"content",fn:function(){return [_c('ui-date',{attrs:{"date":row.alert.startDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Begonnen am "+_vm._s(formattedDate))]}}],null,true)}),_c('br'),_c('ui-date',{attrs:{"date":row.alert.endDate,"format":"LLL","empty-message":"Noch andauernd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Geendet am "+_vm._s(formattedDate))]}}],null,true)})]},proxy:true}],null,true)})]:(row.__typename === 'GatewayHealthNotification')?[_c('ui-tooltip',{attrs:{"hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('domain-ui-gateway-health',{staticClass:"text-overflow",attrs:{"health":row.gatewayHealth,"obsolete":row.gateway.healthChangeDate > row.healthChangeDate,"no-tooltip":"","capitalize":""}})]},proxy:true},{key:"content",fn:function(){return [_c('ui-date',{attrs:{"date":row.healthChangeDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Festgestellt am "+_vm._s(formattedDate))]}}],null,true)}),(row.gateway.healthChangeDate > row.healthChangeDate)?[_c('br'),_c('ui-date',{attrs:{"date":row.gateway.healthChangeDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Aktuell "),_c('domain-ui-gateway-health',{attrs:{"health":row.gateway.health,"inline":"","no-tooltip":""}}),_vm._v(" am "+_vm._s(formattedDate))]}}],null,true)})]:(_vm.healthDescription(row) !== undefined)?[_c('p',[_vm._v(_vm._s(_vm.healthDescription(row)))])]:_vm._e()]},proxy:true}],null,true)})]:(row.__typename === 'SpotHealthNotification')?[_c('ui-tooltip',{attrs:{"hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('domain-ui-spot-health',{staticClass:"text-overflow",attrs:{"health":row.spotHealth,"obsolete":row.spot.healthChangeDate > row.healthChangeDate,"no-tooltip":"","capitalize":""}})]},proxy:true},{key:"content",fn:function(){return [_c('ui-date',{attrs:{"date":row.healthChangeDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Festgestellt am "+_vm._s(formattedDate))]}}],null,true)}),(row.spot.healthChangeDate > row.healthChangeDate)?[_c('br'),_c('ui-date',{attrs:{"date":row.spot.healthChangeDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Aktuell "),_c('domain-ui-spot-health',{attrs:{"health":row.spot.health,"inline":"","no-tooltip":""}}),_vm._v(" am "+_vm._s(formattedDate))]}}],null,true)})]:(_vm.healthDescription(row) !== undefined)?[_c('p',[_vm._v(_vm._s(_vm.healthDescription(row)))])]:_vm._e()]},proxy:true}],null,true)})]:(row.__typename === 'MissedActivityLogEntryNotification')?[_c('ui-date',{attrs:{"date":row.logEntry.latestCheckInDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Abgelaufen am "+_vm._s(formattedDate))]}}],null,true)})]:(row.__typename === 'HeatingSystemNotification')?[_vm._v(_vm._s(row.message))]:[_vm._v("–")]]}},{key:"assignee",fn:function(ref){
var row = ref.row;
return [_c('ui-form',{attrs:{"initial-data":row,"action":function (data) { return _vm.executeChangeAssigneeAction(row, data); }},on:{"submitted":function($event){_vm.assigneeEditor = undefined},"error":function($event){return _vm.$emit('error', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
return [(busy || _vm.assigneeEditor === row.id)?_c('ui-form-field',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return void (_vm.assigneeEditor === row.id ? _vm.assigneeEditor = undefined : undefined); }),expression:"() => void (assigneeEditor === row.id ? assigneeEditor = undefined : undefined)"}],attrs:{"input":"app-admin-input-select-user-control","path":"assignee","disabled":busy},on:{"update":function () { return _vm.$nextTick(submit); }}}):_c('ui-clickable',{attrs:{"disabled":!_vm.changeAssigneeAction},on:{"click":function($event){_vm.assigneeEditor = row.id}}},[_c('div',{staticClass:"text-overflow"},[(row.assignee)?[_vm._v(_vm._s(row.assignee.name))]:[_c('em',[_vm._v("zuweisen")])]],2)])]}}],null,true)})]}},{key:"resolution",fn:function(ref){
var row = ref.row;
return [_c('ui-wizard',{attrs:{"title":"Benachrichtigung bearbeiten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-tooltip',{attrs:{"placement":"left","trigger":row.resolutionDate || row.notes || _vm.editResolutionAction ? 'hover' : 'manual',"hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-clickable',{attrs:{"disabled":!_vm.editResolutionAction},on:{"click":show}},[_c('ui-flex',{attrs:{"items":"center","nowrap":"","stretch":""}},[_c('ui-cell',[_c('img',{staticClass:"block",style:({ visibility: row.notes === null ? 'hidden' : undefined }),attrs:{"src":require("./notes.svg")}})]),_c('ui-cell',[(row.resolved)?_c('img',{staticClass:"block",attrs:{"src":require("./resolved.svg")}}):_c('img',{staticClass:"block",attrs:{"src":require("./unresolved.svg")}})])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('ui-date',{attrs:{"date":row.resolutionDate,"format":"LLL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("Erledigt am "+_vm._s(formattedDate))]}}],null,true)}),(row.notes !== null)?_c('p',[_vm._v(_vm._s(row.notes))]):_vm._e(),(_vm.editResolutionAction)?_c('div',[_vm._v("Benachrichtigung bearbeiten")]):_vm._e()]},proxy:true}],null,true)})]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"initial-data":row,"action":function (data) { return _vm.executeEditResolutionAction(row, data); }},on:{"submitted":function($event){return _vm.onEditResolutionSubmitted(row, hide)},"error":function($event){return _vm.$emit('error', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","path":"resolved","option-label":"Erledigt"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-textarea","path":"notes","label":"Notizen"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,true)})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }