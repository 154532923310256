



















































import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { SortKey } from '@/features/ui/table/model';
import { Order } from '@/types/iot-portal';
import { ArrayProp, ObjectProp, OptionalProp } from '@/util/prop-decorators';
import { orderBy } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { SpotTableData, SpotTableDataLike, SpotTableRow } from './model';
import PrimaryMetric from './PrimaryMetric.vue';

@Component({ components: { PrimaryMetric } })
export default class SpotTable extends Vue {
  @OptionalProp()
  private readonly spots!: SpotTableDataLike;

  @ArrayProp(() => [])
  private readonly hiddenColumns!: string[];

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  private sortKeys: SortKey[] = [];

  private get tableData(): SpotTableData {
    return SpotTableData.from(this.spots);
  }

  private get sortedRows(): SpotTableRow[] {
    return orderBy(
      this.tableData.rows,
      this.sortKeys.map(({ key }) => key),
      this.sortKeys.map(({ direction }) => (direction === Order.ASC ? 'asc' : 'desc')),
    );
  }

  private labelFor(columnName: string): string {
    return atob(columnName.slice(10));
  }
}
