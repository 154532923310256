import { IntervalUnit } from '@/types/iot-portal';

export interface IntervalUnitMeta {
  value: IntervalUnit;
  label: string;
  labelPlural: string;
  labelAdverb: string;
}

export const INTERVAL_UNIT_META: { readonly [k in IntervalUnit]: Readonly<IntervalUnitMeta> } = Object.freeze({
  [IntervalUnit.Day]: {
    value: IntervalUnit.Day,
    label: 'Tag',
    labelPlural: 'Tage',
    labelAdverb: 'Täglich',
  },
  [IntervalUnit.Week]: {
    value: IntervalUnit.Week,
    label: 'Woche',
    labelPlural: 'Wochen',
    labelAdverb: 'Wöchentlich',
  },
  [IntervalUnit.Month]: {
    value: IntervalUnit.Month,
    label: 'Monat',
    labelPlural: 'Monate',
    labelAdverb: 'Monatlich',
  },
  [IntervalUnit.Year]: {
    value: IntervalUnit.Year,
    label: 'Jahr',
    labelPlural: 'Jahre',
    labelAdverb: 'Jährlich',
  },
  [IntervalUnit.QUARTER_HOURLY]: {
    value: IntervalUnit.QUARTER_HOURLY,
    label: 'Viertelstunde',
    labelPlural: 'Viertelstunden',
    labelAdverb: 'Viertelstündlich',
  },
});

export enum DayOfWeek {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

export interface DayOfWeekMeta {
  value: DayOfWeek;
  label: string;
}

export const DAY_OF_WEEK_META: { readonly [k in DayOfWeek]: Readonly<DayOfWeekMeta> } = Object.freeze({
  [DayOfWeek.Monday]: {
    value: DayOfWeek.Monday,
    label: 'Montag',
  },
  [DayOfWeek.Tuesday]: {
    value: DayOfWeek.Tuesday,
    label: 'Dienstag',
  },
  [DayOfWeek.Wednesday]: {
    value: DayOfWeek.Wednesday,
    label: 'Mittwoch',
  },
  [DayOfWeek.Thursday]: {
    value: DayOfWeek.Thursday,
    label: 'Donnerstag',
  },
  [DayOfWeek.Friday]: {
    value: DayOfWeek.Friday,
    label: 'Freitag',
  },
  [DayOfWeek.Saturday]: {
    value: DayOfWeek.Saturday,
    label: 'Samstag',
  },
  [DayOfWeek.Sunday]: {
    value: DayOfWeek.Sunday,
    label: 'Sonntag',
  },
});
