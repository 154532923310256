







































































































































































































































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { GATEWAY_HEALTH_META } from '@/features/domain-ui/gateway-health/constants';
import { SPOT_HEALTH_META } from '@/features/domain-ui/spot-health/constants';
import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Column } from '@/features/ui/table/model';
import { ArrayProp, FunctionProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { ChangeAssigneeAction, ChangeReadAction, EditResolutionAction } from './model';
import { DomainUiNotificationTableNotificationFragment } from './__generated__/DomainUiNotificationTableNotificationFragment';

@Component({
  data() {
    return { assigneeEditor: undefined };
  },
})
export default class NotificationTable extends Mixins(DeviceRoleMapMixin) {
  @ArrayProp(() => [])
  private readonly notifications!: DomainUiNotificationTableNotificationFragment[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @FunctionProp()
  private readonly changeReadAction?: ChangeReadAction;

  @FunctionProp()
  private readonly changeAssigneeAction?: ChangeAssigneeAction;

  @FunctionProp()
  private readonly editResolutionAction?: EditResolutionAction;

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  private assigneeEditor = undefined;
  private readonly gatewayHealthMeta = GATEWAY_HEALTH_META;
  private readonly spotHealthMeta = SPOT_HEALTH_META;

  private readonly columns: Column[] = [
    { name: 'date', label: 'Datum', width: 110 },
    { name: 'location', label: 'Ort', width: 150 },
    { name: 'subject', label: 'Gerät', width: 160 },
    { name: 'details', label: 'Nachricht', width: 150 },
    { name: 'assignee', label: 'Bearbeiter', width: 80 },
    { name: 'status', label: 'Status', width: 80 },
    { name: 'resolution', label: '', align: 'right', width: 0 },
  ];

  private async executeChangeReadAction(notification: DomainUiNotificationTableNotificationFragment): Promise<void> {
    await this.changeReadAction?.(notification, !notification.read);
  }

  private async executeChangeAssigneeAction(
    notification: DomainUiNotificationTableNotificationFragment,
    formData: { assignee: DomainUiNotificationTableNotificationFragment['assignee'] },
  ): Promise<void> {
    await this.changeAssigneeAction?.(notification, formData.assignee);
  }

  private async executeEditResolutionAction(
    notification: DomainUiNotificationTableNotificationFragment,
    formData: {
      resolved: DomainUiNotificationTableNotificationFragment['resolved'];
      notes: DomainUiNotificationTableNotificationFragment['notes'];
    },
  ): Promise<void> {
    await this.editResolutionAction?.(notification, formData.resolved, formData.notes);
  }

  private async onEditResolutionSubmitted(
    notification: DomainUiNotificationTableNotificationFragment,
    hide: () => Promise<void>,
  ): Promise<void> {
    await hide();

    this.$emit('resolution-edited', notification);
  }

  private healthDescription(notification: DomainUiNotificationTableNotificationFragment): string | undefined {
    if (notification.__typename === 'GatewayHealthNotification') {
      return GATEWAY_HEALTH_META[notification.gatewayHealth]?.description;
    }

    if (notification.__typename === 'SpotHealthNotification') {
      return SPOT_HEALTH_META[notification.spotHealth]?.description;
    }

    return undefined;
  }
}
