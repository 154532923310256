import { SpotRole } from '@/types/iot-portal';
import heatSvg from '@/features/domain-ui/eed-consumption/assets/dual-heating.svg';
import waterSvg from '@/features/domain-ui/eed-consumption/assets/drop.svg';

export interface ConsumptionWidgetIcon {
  src: string;
  alt: string;
}

export const CONSUMPTION_WIDGET_META = Object.freeze({
  [SpotRole.HEAT_COST_ALLOCATOR]: {
    label: 'Heizung',
    icon: {
      src: heatSvg,
      alt: 'DualHeatingIcon',
    },
  },
  [SpotRole.HEAT_METER_COUNTER]: {
    label: 'Heizung',
    icon: {
      src: heatSvg,
      alt: 'HeatMeterCounterIcon',
    },
  },
  [SpotRole.WATER_METER_HOT]: {
    label: 'Warmwasser',
    icon: {
      src: waterSvg,
      alt: 'DropIcon',
    },
  },
});
