import { TreeNodeType } from '@/types/iot-portal';

export { TreeNodeType } from '@/types/iot-portal';

export interface TreeNodeTypeMeta {
  value: TreeNodeType;
  label: string;
  labelPlural: string;
  description?: string;
}

export const TREE_NODE_TYPE_META: { readonly [k in TreeNodeType]: Readonly<TreeNodeTypeMeta> } = Object.freeze({
  [TreeNodeType.RootDirectory]: {
    value: TreeNodeType.RootDirectory,
    label: 'Wurzelverzeichnis',
    labelPlural: 'Wurzelverzeichnisse',
  },
  [TreeNodeType.Directory]: {
    value: TreeNodeType.Directory,
    label: 'Verzeichnis',
    labelPlural: 'Verzeichnisse',
  },
  [TreeNodeType.PropertyGroup]: {
    value: TreeNodeType.PropertyGroup,
    label: 'Objektgruppe',
    labelPlural: 'Objektgruppen',
  },
  [TreeNodeType.Property]: {
    value: TreeNodeType.Property,
    label: 'Objekt',
    labelPlural: 'Objekte',
  },
  [TreeNodeType.PropertySubdivision]: {
    value: TreeNodeType.PropertySubdivision,
    label: 'Untereinheit',
    labelPlural: 'Untereinheiten',
  },
  [TreeNodeType.Spot]: {
    value: TreeNodeType.Spot,
    label: 'Gerät',
    labelPlural: 'Geräte',
  },
  [TreeNodeType.Gateway]: {
    value: TreeNodeType.Gateway,
    label: 'Gateway',
    labelPlural: 'Gateways',
  },
  [TreeNodeType.TreeNodeGroup]: {
    value: TreeNodeType.TreeNodeGroup,
    label: 'Group',
    labelPlural: 'Groups',
  },
  [TreeNodeType.PropertySpot]: {
    value: TreeNodeType.PropertySpot,
    label: 'ObjektGerät',
    labelPlural: 'ObjektGerät',
  },
});

export function createTreeNodeTypeComparator(
  field: keyof TreeNodeTypeMeta = 'label',
): (a: TreeNodeType, b: TreeNodeType) => number {
  return (a, b) => (TREE_NODE_TYPE_META[a][field] ?? '').localeCompare(TREE_NODE_TYPE_META[b][field] ?? '');
}
