






import { BooleanProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { VendorApiTypeMeta, VENDOR_API_TYPE_META } from './constants';

@Component
export default class VendorApiType extends Vue {
  @EnumProp(...Object.keys(VENDOR_API_TYPE_META))
  private readonly type!: import('./constants').VendorApiType;

  @BooleanProp()
  private readonly inline!: boolean;

  private get meta(): VendorApiTypeMeta {
    return VENDOR_API_TYPE_META[this.type];
  }
}
