var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.contracts,"align":"left","striped":""},scopedSlots:_vm._u([{key:"contract.label",fn:function(){return [_vm._v("Vertragsnummer"),_c('br'),_vm._v("Kontakt")]},proxy:true},{key:"contract",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.externalId)),_c('br'),(row.businessContact === null)?[_vm._v("–")]:[_c('ui-link',{attrs:{"href":("mailto:" + (row.businessContact.email))}},[_vm._v(_vm._s(row.businessContact.name))])]]}},{key:"sales.label",fn:function(){return [_vm._v("Vertriebspartner"),_c('br'),_vm._v("Angebot")]},proxy:true},{key:"sales",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.salesPartner || '–')),_c('br'),_vm._v(_vm._s(row.offer || '–'))]}},{key:"company.label",fn:function(){return [_vm._v("Vertragspartner"),_c('br'),_vm._v("Adresse")]},proxy:true},{key:"company",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.companyName || '–')),_c('br'),_vm._v(_vm._s(_vm.formatAddress(row.companyAddress) || '–'))]}},{key:"dates.label",fn:function(){return [_vm._v("Vertragsdauer"),_c('br'),_vm._v("Unterschriftsdatum")]},proxy:true},{key:"dates",fn:function(ref){
var row = ref.row;
return [(row.startDate || row.endDate)?[_c('ui-date',{attrs:{"date":row.startDate,"format":"L"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("ab "+_vm._s(formattedDate)+_vm._s(' '))]}}],null,true)}),_c('ui-date',{attrs:{"date":row.endDate,"format":"L"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_vm._v("bis "+_vm._s(formattedDate))]}}],null,true)})]:[_vm._v("–")],_c('br'),_c('ui-date',{attrs:{"date":row.signingDate,"format":"L","empty-message":"–"}})]}},{key:"numbers.label",fn:function(){return [_vm._v("Objekte #"),_c('br'),_vm._v("WE #")]},proxy:true},{key:"numbers",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.propertyCount || '–')),_c('br'),_vm._v(_vm._s(row.apartmentCount || '–'))]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('contract-click', row)}}},[_vm._v("Bearbeiten")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }