var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"rows":_vm.sortedRows,"columns":_vm.tableData.columns,"hidden-columns":_vm.hiddenColumns,"sort-keys":_vm.sortKeys,"render-slices":3},on:{"update:sortKeys":function($event){_vm.sortKeys=$event},"update:sort-keys":function($event){_vm.sortKeys=$event}},scopedSlots:_vm._u([{key:"path",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.spot.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"name.label",fn:function(){return [_vm._v("Name"),_c('br'),_vm._v("Gerätenummer")]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-spot-info',{attrs:{"spot":row.spot},on:{"click":function($event){return _vm.$emit('spot-click', row.spot)}}})]}},_vm._l((_vm.tableData.attributeColumnNames),function(attributeColumnNameLabel){return {key:(attributeColumnNameLabel + ".label"),fn:function(){return [_vm._v("Attribut:"),_c('br'),_vm._v(_vm._s(_vm.labelFor(attributeColumnNameLabel)))]},proxy:true}}),_vm._l((_vm.tableData.attributeColumnNames),function(attributeColumnName){return {key:attributeColumnName,fn:function(ref){
var row = ref.row;
var value = ref.value;
return [_c('div',{staticClass:"text-overflow"},[_c('domain-ui-attribute',{attrs:{"attribute":value}})],1)]}}}),{key:"health.label",fn:function(){return [_vm._v("Zustand"),_c('br'),_vm._v("Letzte Daten")]},proxy:true},{key:"health",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_c('domain-ui-spot-health',{attrs:{"health":row.spot.health,"capitalize":""}}),_c('ui-relative-time',{staticClass:"text-light",attrs:{"date":row.metrics.latestTime,"empty-message":"–","min":"-P1D","format":"L LT"}})],1)]}},{key:"primaryMetric",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_c('primary-metric',{attrs:{"row":row}})],1)]}},_vm._l((_vm.tableData.metricColumnNames),function(metricColumnName){return {key:metricColumnName,fn:function(ref){
var row = ref.row;
var value = ref.value;
return [_c('div',{staticClass:"text-overflow"},[_c('domain-ui-metric-point',{attrs:{"point":value,"spot":row.spot,"inline":""}})],1)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }