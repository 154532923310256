



































import { Component, Mixins } from 'vue-property-decorator';
import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { ConsumptionMixin } from '@/features/domain-ui/eed-consumption/mixins/consumption-mixin';
import { CONSUMPTION_WIDGET_META } from './constants';
import ConsumptionWidgetInfo from './ConsumptionWidgetInfo.vue';
import MonthlyConsumptionComparison from '@/features/domain-ui/eed-consumption/consumption-comparison/MonthlyConsumptionComparison.vue';

type Icon = {
  src: string;
  alt: string;
};

@Component({
  components: { MonthlyConsumptionComparison, ConsumptionWidgetInfo },
})
export default class ConsumptionWidget extends Mixins(ConsumptionMixin) {
  @BooleanProp(false)
  private readonly hasCustomWrapper!: boolean;

  @StringProp()
  private readonly label?: string;

  protected readonly checkableMetric: boolean = true;

  private get icon(): Icon | undefined {
    return CONSUMPTION_WIDGET_META[this.roles[0]]?.icon;
  }

  private get widgetLabel(): string | undefined {
    if (this.label) {
      return this.label;
    }

    return CONSUMPTION_WIDGET_META[this.roles[0]]?.label;
  }

  private get formattedConsumption(): string {
    return this.formatConsumption(this.consumption);
  }

  private get infoIcon(): string {
    return require('../assets/info-icon.svg');
  }
}
