



































import Spinner from '@/components/clickables/Spinner.vue';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import { EditSpotInput, SetTreeNodeAttributeInput } from '@/types/iot-portal';
import { groupBy, partition } from 'lodash';
import type { ParseError } from 'papaparse';
import { NIL, validate } from 'uuid';
import { Component, Vue, Watch } from 'vue-property-decorator';
import editSpotMutation from './edit-spot.gql';
import setSpotAttribute from './set-spot-attribute.gql';
import spotQuery from './spot.gql';
import {
  DomainUiSpotsImportEditSpotMutation,
  DomainUiSpotsImportEditSpotMutationVariables,
} from './__generated__/DomainUiSpotsImportEditSpotMutation';
import {
  DomainUiSpotsImportSetTreeNodeAttributeMutation,
  DomainUiSpotsImportSetTreeNodeAttributeMutationVariables,
} from './__generated__/DomainUiSpotsImportSetTreeNodeAttributeMutation';
import {
  DomainUiSpotsImportSpotQuery,
  DomainUiSpotsImportSpotQueryVariables,
} from './__generated__/DomainUiSpotsImportSpotQuery';

interface ImportRow {
  line: number;
  record: Partial<Record<string, string>>;
}
interface ImportDataItem {
  line: number;
  editSpot?: EditSpotInput;
  setTreeNodeAttributes: SetTreeNodeAttributeInput[];
}
interface ProcessingError {
  line?: number;
  message: string;
  error?: unknown;
}

const ID_FIELD = 'id';
const SPOT_FIELDS = [/^name$/, /^notes$/, /^roomName$/];
const ATTRIBUTE_FIELD = /^attribute_(?!$)(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
const ATTRIBUTE_FIELD_PREFIX = 'attribute_';
const IMPORTABLE_FIELDS = [...SPOT_FIELDS, ATTRIBUTE_FIELD];

// we skip additional rows after the header row. these contain meta data
const SKIP_ROWS = 4;

@Component({
  components: { Spinner },
  data() {
    return {
      file: undefined,
      analyzeErrors: [],
      analyzeWarnings: [],
      importFieldOptions: [],
      importFields: [],
      importRows: [],
      importedSpotCount: 0,
      importErrors: [],
      analyzing: false,
      importing: false,
    };
  },
})
export default class SpotsImport extends Vue {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private file!: File | null;
  private analyzeErrors!: ProcessingError[];
  private analyzeWarnings!: ProcessingError[];
  private importFieldOptions!: Option[];
  private importFields!: string[];
  private importRows!: ImportDataItem[];
  private importedSpotCount!: number;
  private importErrors!: ProcessingError[];
  private analyzing!: boolean;
  private importing!: boolean;

  public declare readonly $refs: { wizard: Vue };

  private get importReady(): boolean {
    return !this.analyzing && !this.importing && this.analyzeErrors.length === 0 && this.importFields.length > 0;
  }

  @Watch('$refs.wizard.visible')
  private resetFile(): void {
    this.file = null;
  }

  @Watch('file')
  // eslint-disable-next-line complexity
  private async analyzeFile(file: File | null): Promise<void> {
    if (this.analyzing || this.importing) {
      return;
    }

    this.analyzeErrors = [];
    this.analyzeWarnings = [];
    this.importFieldOptions = [];
    this.importFields = [];
    this.importRows = [];
    this.importedSpotCount = 0;
    this.importErrors = [];
    this.importing = false;
    this.analyzing = false;

    if (file === null) {
      return;
    }

    this.analyzing = true;

    const { fields, rows, errors } = await parse(file);
    if (errors.length > 0) {
      this.analyzeErrors.push(...errors.map((error) => ({ line: error.row, message: error.message, error })));
      this.analyzing = false;

      return;
    }

    if (!fields.includes(ID_FIELD)) {
      this.analyzeErrors.push({ message: `Spalte "${ID_FIELD}" fehlt` });
    }

    this.importFields = fields.filter((name) => IMPORTABLE_FIELDS.some((regex) => regex.test(name)));
    this.importFieldOptions = this.importFields.map((value) => ({ value, label: rows[0].record[value] ?? value }));
    if (this.importFieldOptions.length === 0) {
      this.analyzeErrors.push({ message: `Keine importierbaren Spalten gefunden` });
    }

    // we had field errors, dont continue analyzing
    if (this.analyzeErrors.length > 0) {
      this.analyzing = false;

      return;
    }

    const skippedRows = rows.slice(SKIP_ROWS);

    const [rowsWithValidIds, rowsWithInvalidIds] = partition(skippedRows, ({ record }) =>
      validate(record.id?.trim() ?? ''),
    );
    this.analyzeWarnings.push(
      ...rowsWithInvalidIds.map(({ line, record }) => ({ line, message: `Ungültige ID ${record.id}` })),
    );

    const [uniqueGroups, duplicationGroups] = partition(
      Object.values(groupBy(rowsWithValidIds, ({ record }) => record.id?.trim())),
      ({ length }) => length === 1,
    );
    const uniqueRows = uniqueGroups.map(([row]) => row);
    this.analyzeErrors.push(
      ...duplicationGroups.flatMap((duplicateRows) =>
        duplicateRows.slice(1).map(({ line }) => ({
          line,
          message: `Gleiche ID wie Zeile ${duplicateRows[0].line}`,
        })),
      ),
    );

    const importRows = [];
    for (const row of uniqueRows) {
      try {
        importRows.push(await this.transformRow(row));
      } catch (e) {
        this.analyzeWarnings.push({ line: row.line, message: e instanceof Error ? e.message : String(e), error: e });
      }
    }

    if (importRows.length === 0) {
      this.analyzeErrors.push({ message: `Keine importierbaren Zeilen gefunden` });
    }

    if (this.analyzeErrors.length === 0) {
      this.importRows = importRows;
    }

    this.analyzing = false;
  }

  private async transformRow(row: ImportRow): Promise<ImportDataItem> {
    const { line, record } = row;

    const extractValue = <T extends keyof EditSpotInput>(field: T): EditSpotInput[T] => {
      const value = record[field]?.trim() ?? '';

      return (value === '' ? null : value) as never;
    };

    const editSpotImportedFields: Partial<EditSpotInput> = {};
    if (this.importFields.includes('name')) {
      editSpotImportedFields.name = extractValue('name');
    }
    if (this.importFields.includes('notes')) {
      editSpotImportedFields.notes = extractValue('notes');
    }
    if (this.importFields.includes('roomName')) {
      editSpotImportedFields.roomName = extractValue('roomName');
    }

    let editSpot;
    if (Object.entries(editSpotImportedFields).length > 0) {
      const { data } = await this.$apollo.query<DomainUiSpotsImportSpotQuery, DomainUiSpotsImportSpotQueryVariables>({
        query: spotQuery,
        variables: { id: record.id ?? NIL },
        fetchPolicy: 'no-cache',
      });
      const spot = data.spots.first;
      editSpot = {
        name: spot.name,
        notes: spot.notes,
        roomName: spot.roomName,
        ...editSpotImportedFields,
        spotId: spot.id,
        metricConfigurations: spot.metricConfigurations,
      };
    }

    const setTreeNodeAttributes = extractAttributes(this.importFields, row);

    return { line, editSpot, setTreeNodeAttributes };
  }

  private async importSpots(hide: () => Promise<void>): Promise<void> {
    if (!this.importReady) {
      return;
    }

    this.importing = true;
    this.importedSpotCount = 0;
    this.importErrors = [];

    for (const { line, editSpot, setTreeNodeAttributes } of this.importRows) {
      if (editSpot !== undefined && this.importFields.some((field) => SPOT_FIELDS.some((regex) => regex.test(field)))) {
        try {
          await this.$apollo.mutate<DomainUiSpotsImportEditSpotMutation, DomainUiSpotsImportEditSpotMutationVariables>({
            mutation: editSpotMutation,
            variables: { input: editSpot },
          });
        } catch (e) {
          this.importErrors.push({ line, message: e instanceof Error ? e.message : String(e), error: e });
        }
      }

      for (const setTreeNodeAttribute of setTreeNodeAttributes) {
        try {
          await this.$apollo.mutate<
            DomainUiSpotsImportSetTreeNodeAttributeMutation,
            DomainUiSpotsImportSetTreeNodeAttributeMutationVariables
          >({
            mutation: setSpotAttribute,
            variables: { input: setTreeNodeAttribute },
          });
        } catch (e) {
          this.importErrors.push({ line, message: e instanceof Error ? e.message : String(e), error: e });
        }
      }

      this.importedSpotCount++;
    }

    this.importing = false;

    if (this.importErrors.length > 0) {
      return;
    }

    const text =
      this.importedSpotCount === 1 ? '1 Gerät wurde importiert' : `${this.importedSpotCount} Geräte wurden importiert`;
    void this.ADD_TOAST_MESSAGES({ messages: [{ text, class: 'success' }] });

    await hide();
  }
}

async function parse(file: File): Promise<{ fields: string[]; rows: ImportRow[]; errors: ParseError[] }> {
  const { parse } = await import('papaparse');

  return new Promise((resolve) => {
    parse<ImportRow['record']>(file, {
      header: true,
      skipEmptyLines: true,
      error: (error) => resolve({ fields: [], errors: [error], rows: [] }),
      complete: ({ errors, data, meta }) =>
        resolve({
          fields: meta.fields ?? [],
          rows: data.map((record, index) => ({ line: index + 1, record })),
          errors,
        }),
    });
  });
}

function extractAttributes(fields: string[], row: ImportRow): SetTreeNodeAttributeInput[] {
  return fields
    .filter((field) => ATTRIBUTE_FIELD.test(field))
    .map((field) => ({
      attributeDefinitionName: atob(field.slice(ATTRIBUTE_FIELD_PREFIX.length)),
      treeNodeId: row.record.id?.trim() ?? NIL,
      value: row.record[field]?.trim() ?? '',
    }));
}
