













import { Input, Option } from '@/features/ui/inputs/model';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';
import { DomainUiInputAttributeFilterAttributeConfigurationFragment } from './__generated__/DomainUiInputAttributeFilterAttributeConfigurationFragment';

function normalizeValue(value: unknown, ...emptyValues: unknown[]): string | undefined {
  return value === undefined || emptyValues.includes(value) ? undefined : String(value);
}

@Component({
  inheritAttrs: false,
  data() {
    return { inputRef: undefined };
  },
})
export default class InputAttributeFilter extends Vue implements Input {
  @Model('update')
  private readonly value!: unknown;

  @ObjectProp()
  private readonly attributeConfiguration?: DomainUiInputAttributeFilterAttributeConfigurationFragment;

  public readonly $refs!: { input: Vue & Input };

  private inputRef?: Vue & Input;

  private get model(): string | undefined {
    switch (this.attributeConfiguration?.__typename) {
      case 'SelectAttributeConfiguration':
      case 'CheckboxAttributeConfiguration':
        return normalizeValue(this.value, null);

      default:
        return normalizeValue(this.value, null, '');
    }
  }

  private set model(value: string | undefined) {
    switch (this.attributeConfiguration?.__typename) {
      case 'SelectAttributeConfiguration':
      case 'CheckboxAttributeConfiguration':
        this.$emit('update', normalizeValue(value, null));

        return;

      default:
        this.$emit('update', normalizeValue(value, null, ''));

        return;
    }
  }

  private get options(): Option[] | undefined {
    switch (this.attributeConfiguration?.__typename) {
      case 'SelectAttributeConfiguration':
        return [
          { value: '', label: '–' },
          ...this.attributeConfiguration.options
            .map((value) => ({ value, label: value }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ];

      case 'CheckboxAttributeConfiguration':
        return [
          { value: '', label: 'Nein' },
          { value: '1', label: 'Ja' },
        ];

      default:
        return undefined;
    }
  }

  public get normalizedValue(): string | undefined {
    return this.model;
  }

  public get empty(): boolean {
    return this.normalizedValue === undefined;
  }

  public get pristine(): boolean {
    return this.inputRef?.pristine ?? true;
  }

  private mounted(): void {
    this.inputRef = this.$refs.input;
  }

  private updated(): void {
    this.inputRef = this.$refs.input;
  }
}
