import { DomainUiTreeNodePathTreeNodeFragment } from '../__generated__/DomainUiTreeNodePathTreeNodeFragment';

export function getPrimaryTreeNode(
  treeNodes: DomainUiTreeNodePathTreeNodeFragment[],
  contextTreeNode?: DomainUiTreeNodePathTreeNodeFragment,
): DomainUiTreeNodePathTreeNodeFragment | undefined {
  for (const treeNode of treeNodes.slice(-2)) {
    if (treeNode.id === contextTreeNode?.id) {
      continue;
    }

    return treeNode;
  }

  return undefined;
}

export function getSecondaryTreeNode(
  treeNodes: DomainUiTreeNodePathTreeNodeFragment[],
  primaryTreeNode: DomainUiTreeNodePathTreeNodeFragment | undefined,
): DomainUiTreeNodePathTreeNodeFragment | undefined {
  for (const treeNode of treeNodes.slice(-1)) {
    if (treeNode.id === primaryTreeNode?.id) {
      continue;
    }

    return treeNode;
  }

  return undefined;
}
